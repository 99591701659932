import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setUserDetails
} from '../../actions/homeAction/homeAction'
import Router from "../../components/router/router";

export class RouterCont extends Component {
  render() {
    return <Router {...this.props} />;
  }
}

export const mapStateToProps = (store) => {
  return {
    common: store.common,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    setUserDetails: (payload) => {
      dispatch(setUserDetails(payload))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RouterCont);
