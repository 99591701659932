import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  Slide,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaPen, FaTrash } from "react-icons/fa";
import { ThreeDots } from "react-loader-spinner";
import GroupAddForm from "./GroupAddForm";
import GroupEditForm from "./GroupEditForm";
import AdminMenu from "../AdminMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
  { id: "name", label: "Title" },
  { id: "start_date", label: "Start Date" },
  { id: "end_date", label: "End Date" },
  { id: "is_active", label: "Status" },
  { id: "action", label: "Action" },
];

export default function Groups() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("lg");
  const authToken = localStorage.getItem("authToken");

  //Fetch--------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://imtihaan.talabulilm.com/imtihaan/admin/group_list",
          {
            headers: {
              Authorization: "Basic " + authToken,
            },
          }
        );
        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //DialogBox Add group -------------------------------------
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //Delete Processes------------------------------------------

  const handleClickDelete = async (rowId) => {
    const result = window.confirm("Are you sure you want to delete this item?");
    if (result) {
      try {
        const response = await axios.delete(
          `https://imtihaan.talabulilm.com/imtihaan/admin/delete/groups/id/${rowId}`,
          {
            headers: {
              Authorization: "Basic " + authToken,
            },
          }
        );
        if (response.status === 200) {
          toast.success("Item deleted successfully!");
        } else {
          console.error("Failed to delete item.");
          toast.error("Failed to delete item.");
        }
      } catch (error) {
        toast.error("Error deleting item");
        console.error("Error deleting item:", error);
      }
    } else {
      // console.log("Delete operation cancelled!");
      toast.info("Delete operation cancelled!");
    }
  };
  //DialogBox Edit group------------------------------------------
  const [openEdit, setOpenEdit] = React.useState(false);
  const [selectRowId, setSelectRowId] = React.useState([{}]);

  const handleClickOpenEdit = async (id) => {
    setSelectRowId(id);
    try {
      const response = await axios.get(
        `https://imtihaan.talabulilm.com/imtihaan/admin/group_data/${id}`,
        {
          headers: {
            Authorization: "Basic " + authToken,
          },
        }
      );
      setEditData(response.data);
      setOpenEdit(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error
    }
    setOpenEdit(true);
  };
  // console.log(selectRowId, "rowID");
  const handleCloseEdit = () => {
    setOpenEdit(false);
    setSelectRowId("");
  };

  //------------------Edit Form Data-------------------------------

  const [editData, setEditData] = useState([]);
  useEffect(() => {
    if (openEdit && selectRowId) {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `https://imtihaan.talabulilm.com/imtihaan/admin/group_data/${selectRowId}`,
            {
              headers: {
                Authorization: "Basic " + authToken,
              },
            }
          );

          setEditData(response.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [selectRowId, openEdit]);

  // console.log(selectRowId, "id");
  //--------------------------------------------------------------------
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        PaperProps={{ style: { maxHeight: "80vh", minHeight: "50vh" } }}
      >
        <GroupAddForm handleClose={handleClose} data={data} />
      </Dialog>

      <Dialog
        open={openEdit}
        onClose={handleCloseEdit}
        TransitionComponent={Transition}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        PaperProps={{ style: { maxHeight: "80vh", minHeight: "50vh" } }}
      >
        <GroupEditForm
          handleCloseEdit={handleCloseEdit}
          editData={editData.data}
          selectRowId={selectRowId}
        />
      </Dialog>

      <div className="container-admin" style={{ marginTop: "20px" }}>
        <AdminMenu />
        <Paper
          sx={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            marginLeft: "10px",
          }}
        >
          <div>
            <span
              style={{
                opacity: "0.8",
                margin: "20px",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {" "}
              Groups List
              <Button variant="outlined" onClick={handleClickOpen}>
                + Add
              </Button>
            </span>
            <hr className="underline"></hr>
          </div>
          {data.length === 0 ? (
            <>
              <div
                style={{
                  width: "50px",
                  margin: "auto",
                }}
              >
                <ThreeDots color="darkblue" size={50} />
              </div>
              <p style={{ textAlign: "center" }}>Please Wait...</p>
            </>
          ) : (
            <>
              <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align="left"
                          style={{
                            minWidth: 150,
                            fontSize: "19px",
                            fontWeight: "bold",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow hover key={index}>
                            <TableCell align="left">{row.name}</TableCell>
                            <TableCell align="left">{row.start_date}</TableCell>
                            <TableCell align="left">{row.end_date}</TableCell>
                            <TableCell align="left">
                              {parseInt(row.is_active, 10) === 1
                                ? "Active"
                                : parseInt(row.is_active, 10) === 0
                                ? "In Active"
                                : "Unknown"}
                            </TableCell>
                            <TableCell align="left">
                              <FaPen
                                size={18}
                                color="blue"
                                alt="penlogo"
                                className="icon"
                                style={{ marginRight: "10px" }}
                                onClick={() => handleClickOpenEdit(row.id)}
                              />
                              <FaTrash
                                size={18}
                                color="red"
                                alt="trashlogo"
                                className="icon"
                                onClick={() => handleClickDelete(row.id)}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="span"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) => {
                  return (
                    <span
                      style={{
                        color: "blue",
                        fontWeight: "bold",
                        display: "flex",
                      }}
                    >
                      {`${from}-${to} of ${count}`}
                    </span>
                  );
                }}
                sx={{
                  margin: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  "& .MuiButtonBase-root": {
                    borderRadius: "20px",
                    padding: "10px",
                    marginRight: "10px",
                  },
                  "& .MuiSelect-selectMenu": {
                    color: "red",
                    paddingRight: "10px",
                  },
                  "& .MuiTablePagination-actions button": {
                    backgroundColor: "#545cd8",
                    gap: "10px",
                    color: "white",
                    borderRadius: "5px",
                    padding: "8px",
                  },
                }}
              />
            </>
          )}
        </Paper>
      </div>
      <ToastContainer />
    </>
  );
}
