import React, { useState, useEffect } from "react";
import {
  AppBar,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
  Checkbox,
} from "@mui/material";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DropdownOptions from "../DashBoard/DropDown";
import { toast } from "react-toastify";
const authToken = localStorage.getItem("authToken");
const QuizAddForm = ({ handleClose }) => {
  const [editorData, setEditorData] = useState("");
  // const [isActive, setIsAcive] = useState(false);

  const [formData, setFormData] = useState({
    group_id: "",
    language: "",
    report_name: "",
    folder_name: "",
    name: "",
    max_score: "",
    time_limit: "",
    slide_count: "",
    total_iteractions: "",
    pass_value: "",
    start_date: "",
    end_date: "",
    is_active: "0",
  });

  const handleChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        "https://imtihaan.talabulilm.com/imtihaan/admin/quiz_data",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${authToken}`,
          },
          body: JSON.stringify({
            ...formData,
            description: editorData,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit form data");
      }

      const responseData = await response.json();

      toast.success("Form data submitted successfully:", responseData);
      // console.log("Form data submitted successfully:", responseData);
      // console.log(formData, "fdata");

      //reset the form
      setFormData({
        group_id: "",
        language: "",
        report_name: "",
        folder_name: "",
        name: "",
        max_score: "",
        time_limit: "",
        slide_count: "",
        total_iteractions: "",
        pass_value: "",
        start_date: "",
        end_date: "",
        is_active: "0",
      });
      setEditorData("");
    } catch (error) {
      console.error("Error submitting form data:", error.message);
      // console.log(formData, "fdata");
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;

    setFormData({
      ...formData,
      [name]: inputValue,
    });
  };

  const handleSelectChange = (value) => {
    // console.log(value.target.value, "vv");
    setFormData((prevFormData) => ({
      ...prevFormData,
      group_id: value.target.value,
    }));
  };

  return (
    <>
      <AppBar sx={{ position: "static", backgroundColor: "#1D3557" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Add Quiz Info
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <FaTimes />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Form onSubmit={handleSubmit} name="Quiz Add Form" id="QuizAddForm">
          <Row>
            {/* Left Form */}
            <Col xs={12} md={6}>
              <Form.Group className="mb-2">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <Form.Group controlId="groupID">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Form.Label>Groups</Form.Label>
                      <span style={{ alignSelf: "flex-start" }}>
                        <DropdownOptions
                          handleSelectChange={handleSelectChange}
                          name="group_id"
                        />
                      </span>
                    </div>
                  </Form.Group>

                  <Form.Group controlId="language">
                    <Form.Label>Language</Form.Label>
                    <Form.Control
                      as="select"
                      name="language"
                      value={formData.language}
                      onChange={handleInputChange}
                    >
                      <option value={""} disabled>
                        Choose
                      </option>
                      <option value={"lsd"}>LSD</option>
                      <option value={"eng"}>ENG</option>
                      <option value={"guj"}>GUJ</option>
                      <option value={"frn"}>FRN</option>
                      <option value={"ind"}>IND</option>
                      <option value={"arb"}>ARB</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="reportName">
                    <Form.Label>Report Name</Form.Label>
                    <Form.Control
                      name="report_name"
                      type="text"
                      value={formData.report_name}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="folderName">
                    <Form.Label>Folder Name</Form.Label>
                    <Form.Control
                      name="folder_name"
                      type="text"
                      value={formData.folder_name}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="name">
                    <Form.Label> Name</Form.Label>
                    <Form.Control
                      name="name"
                      type="text"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="maxScore">
                    <Form.Label>Max Score</Form.Label>
                    <Form.Control
                      name="max_score"
                      type="text"
                      value={formData.max_score}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="timeLimit">
                    <Form.Label>Time Limit</Form.Label>
                    <Form.Control
                      name="time_limit"
                      type="number"
                      value={formData.time_limit}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="slideCount">
                    <Form.Label>Slide Count</Form.Label>
                    <Form.Control
                      name="slide_count"
                      type="text"
                      value={formData.slide_count}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="totalIteractions">
                    <Form.Label>Total Iteractions</Form.Label>
                    <Form.Control
                      name="total_iteractions"
                      type="text"
                      value={formData.total_iteraction}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="passValue">
                    <Form.Label>Pass Value</Form.Label>
                    <Form.Control
                      name="pass_value"
                      type="text"
                      value={formData.pass_value}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </div>
              </Form.Group>
              <Form.Text>
                <span style={{ color: "red" }}>Note</span>: End Date must be
                Higher Than the Start Date
              </Form.Text>
              <Row>
                <Col>
                  <Form.Group controlId="startDate">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="start_date"
                      value={formData.start_date}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="is_active">
                    <Form.Check
                      name="is_active"
                      type="checkbox"
                      label="Is Active"
                      checked={formData.is_active}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Button style={{ marginTop: "20px" }} type="submit">
                    Submit
                  </Button>
                </Col>
                <Col>
                  <Form.Group controlId="endDate">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="end_date"
                      value={formData.end_date}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            {/* Right Form */}
            <Col xs={12} md={6}>
              <Form.Label>Description</Form.Label>
              <CKEditor
                name="description"
                editor={ClassicEditor}
                data={editorData}
                value={formData.description}
                onChange={handleChange}
                className="ck-editor__editable"
              />
            </Col>
          </Row>
        </Form>
      </DialogContent>
    </>
  );
};

export default QuizAddForm;
