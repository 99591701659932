import UNIVERSAL from "../../config/config";
import {
  SET_MARK_LIST,
  SET_TOTAL_MARK,
  SET_GROUP_DATA,
  SET_RANK,
  SET_LEADERBOARD_DATA,
} from "../../constants/commonConst";
import { set_loader, unset_loader } from "../loader/loaderAction";
import md5 from "md5";
var base64 = require("base-64");
const authToken = localStorage.getItem("authToken");
export function setMarkList(payload) {
  return {
    type: SET_MARK_LIST,
    payload: payload,
  };
}

export function setTotalMark(payload) {
  return {
    type: SET_TOTAL_MARK,
    payload: payload,
  };
}

export function setGroupData(payload) {
  return {
    type: SET_GROUP_DATA,
    payload: payload,
  };
}

export function setRank(payload) {
  return {
    type: SET_RANK,
    payload: payload,
  };
}

export function seTLeaderboardData(payload) {
  return {
    type: SET_LEADERBOARD_DATA,
    payload: payload,
  };
}

// GET APIs
export const getMarkList = (its_id, id) => {
  return (dispatch) => {
    dispatch(set_loader());
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic " + authToken);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    return fetch(
      `https://imtihaan.talabulilm.com/imtihaan/user/obtain_marks_list/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(unset_loader());
        dispatch(setMarkList(responseJson.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getTotalMark = (its_id, id) => {
  return (dispatch) => {
    dispatch(set_loader());
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic " + authToken);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    return fetch(
      `https://imtihaan.talabulilm.com/imtihaan/user/total_marks/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(unset_loader());
        dispatch(setTotalMark(responseJson.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getGroupData = (id) => {
  return (dispatch) => {
    dispatch(set_loader());
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic " + authToken);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    return fetch(
      `https://imtihaan.talabulilm.com/imtihaan/user/group_data/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(unset_loader());
        dispatch(setGroupData(responseJson.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getRank = (its_id, id) => {
  return (dispatch) => {
    dispatch(set_loader());
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic " + authToken);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    return fetch(
      `https://imtihaan.talabulilm.com/imtihaan/user/rank/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(unset_loader());
        dispatch(setRank(responseJson.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getLeaderboard = (its_id, group_id) => {
  return (dispatch) => {
    dispatch(set_loader());
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic " + authToken);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    return fetch(
      `https://imtihaan.talabulilm.com/imtihaan/user/top100Users/${group_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(unset_loader());
        dispatch(seTLeaderboardData(responseJson.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
