import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Button,
} from "@mui/material";

import { FaDownload } from "react-icons/fa";
import React, { useEffect, useState } from "react";

import { ThreeDots } from "react-loader-spinner";

const columns = [
  { id: "name", label: "Name" },
  { id: "jamaat", label: "Jamaat" },
  { id: "jamiat", label: "Jamiat" },
  { id: "phone", label: "Phone" },
  { id: "email", label: "Email" },
  { id: "city", label: "City" },
  { id: "country", label: "Country" },
];

export default function JamaatWiseReport({ jamaatWiseData, jamaat }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState(jamaatWiseData);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // --------------CSV-----------------------------
  const downloadCsv = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      data.map((row) => Object.values(row).join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "jamaatWiseReport.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  // --------------Filter-----------------------------
  const [filteredData, setFilteredData] = useState([]);

  const handleFilterChange = (event, columnId) => {
    const inputValue = event.target.value.toLowerCase();
    const filteredRows = data.filter((row) =>
      row[columnId].toLowerCase().includes(inputValue)
    );
    setFilteredData(filteredRows);
  };
  // --------------Filter Table as per the Jammat Name-----------------------------

  return (
    <div className="container-admin">
      <Paper
        sx={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          margin: "20px",
        }}
      >
        <div
          style={{
            opacity: "0.8",
            margin: "20px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p style={{ margin: "0" }}>TOTAL USERS OF {jamaat}</p>
          <Button onClick={() => window.location.reload()} variant="outlined">
            {" "}
            back
          </Button>
        </div>
        <hr className="underline" />

        {data.length === 0 ? (
          <span
            style={{
              display: "block",
              width: "50px",
              margin: "auto",
              position: "inline",
              textAlign: "center",
            }}
          >
            <ThreeDots color="darkblue" size={50} />
          </span>
        ) : (
          <>
            <span
              onClick={downloadCsv}
              style={{
                fontSize: "0.9em",
                cursor: "pointer",
                marginLeft: "20px",
                marginTop: "10px",
                color: "green",
              }}
            >
              <FaDownload size={15} color="green" /> Download Csv
            </span>
            <TableContainer sx={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align="left"
                        style={{
                          minWidth: 150,
                          fontSize: "19px",
                          color: "blue",
                        }}
                      >
                        {column.label}
                        <TextField
                          id={column.id + "-filter"}
                          label={`Filter ${column.label}`}
                          variant="outlined"
                          size="small"
                          onChange={(event) =>
                            handleFilterChange(event, column.id)
                          }
                          style={{ marginLeft: "10px" }}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(filteredData.length > 0 ? filteredData : data)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow hover key={index}>
                          <TableCell align="left">{row.name}</TableCell>
                          <TableCell align="left">{row.jamaat}</TableCell>
                          <TableCell align="left">{row.jamiat}</TableCell>
                          <TableCell align="left">{row.phone}</TableCell>
                          <TableCell align="left">{row.email}</TableCell>
                          <TableCell align="left">{row.city}</TableCell>
                          <TableCell align="left">{row.country}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="span"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={({ from, to, count }) => {
                return (
                  <span
                    style={{
                      color: "blue",
                      fontWeight: "bold",
                      display: "flex",
                    }}
                  >
                    {`${from}-${to} of ${count}`}
                  </span>
                );
              }}
              sx={{
                margin: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "& .MuiButtonBase-root": {
                  borderRadius: "20px",
                  padding: "10px",
                  marginRight: "10px",
                },
                "& .MuiSelect-selectMenu": {
                  color: "red",
                  paddingRight: "10px",
                },
                "& .MuiTablePagination-actions button": {
                  backgroundColor: "#545cd8",
                  gap: "10px",
                  color: "white",
                  borderRadius: "5px",
                  padding: "8px",
                },
              }}
            />
          </>
        )}
      </Paper>
    </div>
  );
}
