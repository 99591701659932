import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  footer: {
    backgroundColor: "#1d3557",
    paddingTop: "1rem",
    paddingRight: "2rem",
    paddingBottom: "1.5rem",
    paddingLeft: "2rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  footerIcon: {
    width: "30px",
    height: "30px",
    objectFit: "cover",
    marginTop: "10px",
    marginRight: "30px",
    marginBottom: "10px",
    marginLeft: "30px",
    borderRadius: "8px",
  },

  copyRight: {
    letterSpacing: "0.05em",
    color: "#f1faee",
    fontSize: "15px",
    fontFamily: "Roboto",
  },

  divider: {
    fontSize: "25px",
    marginRight: "20px",
    marginLeft: "20px",
    color: "#f1faee",
    fontFamily: "Roboto",
  },

  privacyPolicy: {
    fontSize: "12px",
    cursor: "pointer",
    textDecoration: "none",
    color: "#f1faee",
    fontSize: "15px",
    fontFamily: "Roboto",
  },
});

function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <div>
        <a
          href="https://www.youtube.com/channel/UCmvMIUNSfW77jIQT8OCv5xg"
          target="_blank"
        >
          <img
            alt="Youtube  "
            fetchpriority="high"
            src="https://static.wixstatic.com/media/11062b_5f4e45b417034e48a0422fb4d97c4c2c~mv2.png/v1/fill/w_38,h_38,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/11062b_5f4e45b417034e48a0422fb4d97c4c2c~mv2.png"
            className={classes.footerIcon}
            href="https://www.youtube.com/channel/UCmvMIUNSfW77jIQT8OCv5xg"
          ></img>
        </a>
        <a
          href="https://twitter.com/mhbtalabulilm?s=20&t=rS_S2s2mKjETL81bA3XbNA"
          target="_blank"
        >
          <img
            alt="Twitter"
            fetchpriority="high"
            src="https://static.wixstatic.com/media/59687ffffc2042f885062ce2b0744381.png/v1/fill/w_38,h_38,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/59687ffffc2042f885062ce2b0744381.png"
            className={classes.footerIcon}
          ></img>
        </a>
        <a
          href="https://www.instagram.com/mhbtalabulilm/?hl=en&__coig_restricted=1"
          target="_blank"
        >
          <img
            alt="Instagram"
            fetchpriority="high"
            src="https://static.wixstatic.com/media/11062b_9b5a3b3607694630a7253c5fc4ff6476~mv2.png/v1/fill/w_38,h_38,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/11062b_9b5a3b3607694630a7253c5fc4ff6476~mv2.png"
            className={classes.footerIcon}
          ></img>
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.talabulilm.istibsaarmob&hl=en&gl=US&pli=1"
          target="_blank"
        >
          <img
            alt="Istibsaar App"
            fetchpriority="high"
            src="https://static.wixstatic.com/media/227974_da02ea8a22e34e5d8d9cffe1684254ff~mv2.png/v1/fill/w_38,h_38,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/227974_da02ea8a22e34e5d8d9cffe1684254ff~mv2.png"
            className={classes.footerIcon}
          ></img>
        </a>
      </div>
      <div className={classes.footerText}>
        <span className={classes.copyRight}>
          © 2022 MAHAD AL-HASANAAT AL-BURHANIYAH, ALL RIGHTS RESERVED
        </span>
        <span className={classes.divider}>|</span>
        <span>
          <a
            className={classes.privacyPolicy}
            href="https://www.talabulilm.com/privacy-policy.html"
            target="_blank"
            rel="noreferrer noopener"
          >
            PRIVACY POLICY
          </a>
        </span>
      </div>
    </footer>
  );
}

export default Footer;
