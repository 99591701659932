import React, { Component } from "react";
import { connect } from "react-redux";
import { getLeaderboard } from "../../actions/marksheetAction/marksheetAction";
import LeaderBoard from "../../components/leaderBoard/leaderBoard";

export class LeaderBoardCont extends Component {
  render() {
    return <LeaderBoard {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    common: store.common,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    getLeaderboard: (user_its, group_id) => {
      dispatch(getLeaderboard(user_its, group_id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LeaderBoardCont);
