import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Link,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 120,
    [theme.breakpoints.down("sm")]: {
      height: 100,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontSize: 24,
    marginLeft: 25,
  },
  appBar: {
    height: 120,
    backgroundColor: "#1D3557",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      height: 80,
    },
  },
  appBarDesktop: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  appBarMobile: {
    visibility: "hidden",
    height: 0,
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 20px",
    [theme.breakpoints.down("sm")]: {
      visibility: "visible",
      height: "auto",
    },
  },
  userImage: {
    width: 60,
    height: 80,
    borderRadius: 25,
    marginLeft: 10,
  },
  menuContainer: {
    display: "flex",
    flexDirection: "column",
  },
  menuItem: {
    color: "#F1FAEE",
    fontSize: 16,
  },
  userDetails: {
    color: "#ffffff",
    fontSize: 18,
    fontWeight: 700,
    marginRight: 10,
  },
  links: {
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    marginTop: 8,
  },
  userContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  h6: {
    fontSize: 24,
  },
}));

export default function CustomAppBar(props) {
  const { common, setUserDetails } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      name: "Araiz",
      link: "https://www.talabulilm.com/araiz",
    },
    {
      name: "Istifaadah",
      link: "https://istifaadah.talabulilm.com",
    },
    {
      name: "Istibsaar",
      link: "https://www.talabulilm.com/istibsaar_new",
    },
    {
      name: "Workshops",
      link: "https://www.talabulilm.com/workshops",
    },
    {
      name: "Minhat Taalimiyah",
      link: "https://www.talabulilm.com/minhat",
    },
  ];

  const list = () => (
    <div role="presentation" onClick={() => setIsDrawerOpen(false)}>
      <List>
        <ListItem>
          <ListItemIcon>
            <CloseIcon
              style={{ position: "absolute", right: 15, cursor: "pointer" }}
            />
          </ListItemIcon>
        </ListItem>
      </List>
      <List>
        <ListItem
          component="a"
          href="https://www.talabulilm.com/profile/"
          button
        >
          <ListItemText primary="Profile" />
        </ListItem>
      </List>
      <Divider />
      <List>
        {menuItems.map((item, index) => (
          <ListItem component="a" href={item.link} button key={index}>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem
          component="a"
          href="https://www.talabulilm.com/log-out/"
          button
        >
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        {list()}
      </Drawer>
      <AppBar className={classes.appBar} position="static">
        <Toolbar className={classes.appBarDesktop}>
          <Link href="/" style={{ textDecoration: "none", color: "#ffffff" }}>
            <img
              src={"https://www.talabulilm.com/images/logo-white-small.png"}
              alt="mhb-logo"
              style={{ maxHeight: 114 }}
            />
          </Link>
          <Typography
            variant="h6"
            className={classes.title}
            style={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <Link href="/" style={{ textDecoration: "none", color: "#ffffff" }}>
              Imtihaan Home
            </Link>
            <img
              src={"https://imtihaan.talabulilm.com/1446logo.png"}
              style={{ maxHeight: 110 }}
            />
          </Typography>
          <div className={classes.menuContainer}>
            <div className={classes.userContainer}>
              <Typography className={classes.userDetails} variant="h6">
                {common.userDetails.name && common.userDetails.name}
              </Typography>
            </div>
            <Typography className={classes.links}>
              {menuItems.map((item, idx) => (
                <Link
                  key={idx}
                  href={item.link}
                  target="_blank"
                  className={classes.menuItem}
                >
                  {item.name}
                </Link>
              ))}
            </Typography>
          </div>
          <img
            style={{ cursor: "pointer" }}
            onClick={handleClick}
            className={classes.userImage}
            src={`https://www.talabulilm.com/mumin_images/${common.userDetails.its_id}.png`}
            alt="logo"
          />
        </Toolbar>
        <div className={classes.appBarMobile}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Link href="/" style={{ textDecoration: "none", color: "#ffffff" }}>
              <img
                src={"https://www.talabulilm.com/images/logo-white-small.png"}
                style={{ width: 50 }}
                alt="logo"
              />
            </Link>
          </div>
          {/* <Typography variant="h6" > */}
          <Link
            href="/"
            style={{
              textDecoration: "none",
              color: "#ffffff",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src="https://imtihaan.talabulilm.com/1446logo.png"
              style={{ maxHeight: 50 }}
            />
          </Link>
          {/* </Typography> */}
          <IconButton onClick={() => setIsDrawerOpen(true)}>
            <MenuIcon style={{ color: "#fff" }} size="large" />
          </IconButton>
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ marginTop: 50 }}
        >
          <Link
            style={{ textDecoration: "none" }}
            href="https://www.talabulilm.com/profile/"
          >
            <MenuItem
              style={{
                width: 300,
                backgroundColor: "#F1FAEE",
                color: "#E63946",
              }}
              onClick={handleClose}
            >
              My Profile
            </MenuItem>
          </Link>
          <Link
            style={{ textDecoration: "none" }}
            href="https://www.talabulilm.com/log-out/"
          >
            <MenuItem
              style={{
                width: 300,
                backgroundColor: "#F1FAEE",
                color: "#E63946",
              }}
              onClick={handleClose}
            >
              Logout
            </MenuItem>
          </Link>
        </Menu>
      </AppBar>
    </div>
  );
}
