import React from "react";
import DashBoard from "./DashBoard/DashBoard";
import { useSpring, animated } from "react-spring";

export default function Admin(props) {
  const slideInFromLeft = useSpring({
    from: { opacity: 0, transform: "translateX(-100%)" },
    to: { opacity: 1, transform: "translateX(0%)" },
    config: { tension: 250, friction: 30 },
  });

  return (
    <div className="container-fluid" style={{ padding: "0px" }}>
      <div className="col">
        <div className="row">
          <animated.div style={slideInFromLeft}>
            <DashBoard />
          </animated.div>
        </div>
      </div>
    </div>
  );
}
