import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import AdminMenu from "../AdminMenu";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const GeneralInfo = () => {
  const [groupsEditorData, setGroupsEditorData] = useState({});
  const [quizzesEditorData, setQuizzesEditorData] = useState({});

  const handleGroupsEditorChange = (event, editor) => {
    const data = editor.getData();
    setGroupsEditorData(data);
  };

  const handleQuizzesEditorChange = (event, editor) => {
    const data = editor.getData();
    setQuizzesEditorData(data);
  };
  const authToken = localStorage.getItem("authToken");
  const handleSubmitGroups = async (event) => {
    event.preventDefault();
    // console.log("Groups editor data:", groupsEditorData);
    try {
      const response = await fetch(
        "https://imtihaan.talabulilm.com/imtihaan/admin/general_info",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${authToken}`,
          },
          body: JSON.stringify({
            label: "groups",
            text: groupsEditorData,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit form data");
      }

      const responseData = await response.json();
      alert("Form data submitted successfully:", responseData);
    } catch (error) {
      console.error("Error submitting form data:", error.message);
    }
  };

  const handleSubmitQuizzes = async (event) => {
    event.preventDefault();
    // console.log("Quizzes editor data:", quizzesEditorData);
    try {
      const response = await fetch(
        "https://imtihaan.talabulilm.com/imtihaan/admin/general_info",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${authToken}`,
          },
          body: JSON.stringify({
            label: "quizzes",
            text: quizzesEditorData,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit form data");
      }

      const responseData = await response.json();
      alert("Form data submitted successfully:", responseData);
    } catch (error) {
      console.error("Error submitting form data:", error.message);
    }
  };

  return (
    <div className="container-admin" style={{ marginTop: "20px" }}>
      <AdminMenu />
      <div className="admin-table">
        <Form id="GroupInfoTab" name="GroupInfoForm">
          <Form.Label style={{ fontSize: "0.80em" }}>Groups Info</Form.Label>
          <CKEditor
            editor={ClassicEditor}
            data={groupsEditorData}
            onChange={handleGroupsEditorChange}
          />
          <Button
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              width: "8em",
              fontSize: "0.8rem",
            }}
            type="submit"
            onClick={handleSubmitGroups}
          >
            Submit
          </Button>
        </Form>

        <Form id="QuizInfoTab" name="QuizInfoForm">
          <Form.Label style={{ fontSize: "0.88em" }}>Quizzes Info</Form.Label>
          <CKEditor
            editor={ClassicEditor}
            data={quizzesEditorData}
            onChange={handleQuizzesEditorChange}
          />
          <Button
            style={{
              marginTop: "20px",
              width: "8em",
              fontSize: "0.8rem",
            }}
            type="submit"
            onClick={handleSubmitQuizzes}
          >
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
};
export default GeneralInfo;
