import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { ThreeDots } from "react-loader-spinner";

function AdminDetails({ baseUrl, lastUrl }) {
  const [url3Data, setUrl3Data] = useState([]);
  const [url4Data, setUrl4Data] = useState([]);
  const [url5Data, setUrl5Data] = useState([]);
  const [chart2, setChart2] = useState([]);
  const authToken = localStorage.getItem("authToken");

  const chartData = [
    ["Task", "Hours per total"],
    [
      "LSD",
      url3Data[3]?.getTotalUniqueUsers
        ? parseInt(url3Data[3].getTotalUniqueUsers)
        : 0,
    ],
    [
      "ENG",
      url3Data[2]?.getTotalUniqueUsers
        ? parseInt(url3Data[2].getTotalUniqueUsers)
        : 0,
    ],
    [
      "GUJ",
      url3Data[1]?.getTotalUniqueUsers
        ? parseInt(url3Data[1].getTotalUniqueUsers)
        : 0,
    ],
    [
      "ARB",
      url3Data[0]?.getTotalUniqueUsers
        ? parseInt(url3Data[0].getTotalUniqueUsers)
        : 0,
    ],
  ];

  const chart2Data = chart2.map((item) => ({
    value: parseInt(item.avgTime),
  }));

  useEffect(() => {
    const fetchUrlData = async () => {
      if (!lastUrl) {
        return;
      }
      let myHeaders = new Headers();
      myHeaders.append("Authorization", "Basic " + authToken);
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      try {
        const url3 = `${baseUrl}langauge_wise_user_count/all/${lastUrl}/all`;
        const response = await fetch(url3, requestOptions);
        const data = await response.json();
        setUrl3Data(data.data);

        const url4 = `${baseUrl}total_completed_unique_user/all/${lastUrl}/all`;
        const response4 = await fetch(url4, requestOptions);
        const data4 = await response4.json();
        setUrl4Data(data4.data);

        const url5 = `${baseUrl}total_unique_user/all/${lastUrl}/all`;
        const response5 = await fetch(url5, requestOptions);
        const data5 = await response5.json();
        setUrl5Data(data5.data);

        const url6 = `${baseUrl}total_unique_user_paper_wise/all/${lastUrl}/all`;
        const response6 = await fetch(url6, requestOptions);
        const data6 = await response6.json();
        setChart2(data6.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchUrlData();
  }, [baseUrl, lastUrl]);

  return (
    <>
      {url3Data.length === 0 ? (
        <div className="admin-table text-center">
          <ThreeDots color="darkblue" size={50} />
        </div>
      ) : (
        <div className="admin-table">
          <div className="row">
            <div className="col-md-8">
              <div
                className="data-container mb-4"
                style={{ fontWeight: "700", color: "#495057" }}
              >
                Total Unique Users
                <div
                  className="widget-numbers text-success"
                  style={{ fontSize: "1.8rem" }}
                >
                  {url5Data}
                </div>
              </div>

              <div
                className="data-container mb-4"
                style={{ fontWeight: "700", color: "#495057" }}
              >
                Total Completed Users
                <div
                  className="widget-numbers text-success"
                  style={{ fontSize: "1.8rem" }}
                >
                  {url4Data}
                </div>
              </div>
              <hr className="underline" />
              <div className="row">
                <div className="col-md-6">
                  {url3Data[0] && (
                    <div className="data-container mb-4">
                      Total ARB Users
                      <div
                        className="widget-numbers text-danger"
                        style={{ fontSize: "1.8rem" }}
                      >
                        {url3Data[0]?.getTotalUniqueUsers || ""}
                      </div>
                    </div>
                  )}
                  {url3Data[1] && (
                    <div className="data-container">
                      Total GUJ Users
                      <div
                        className="widget-numbers text-danger"
                        style={{ fontSize: "1.8rem" }}
                      >
                        {url3Data[1]?.getTotalUniqueUsers || ""}
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-md-6">
                  {url3Data[2] && (
                    <div className="data-container mb-4">
                      Total ENG Users
                      <div
                        className="widget-numbers text-danger"
                        style={{ fontSize: "1.8rem" }}
                      >
                        {url3Data[2]?.getTotalUniqueUsers || ""}
                      </div>
                    </div>
                  )}
                  {url3Data[3] && (
                    <div className="data-container">
                      Total LSD Users
                      <div
                        className="widget-numbers text-danger"
                        style={{ fontSize: "1.8rem" }}
                      >
                        {url3Data[3]?.getTotalUniqueUsers || ""}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                style={{ margin: "0.2em", width: "100%", overflow: "hidden" }}
              >
                <PieChart
                  hideLabels={true}
                  className="pie-chart-legend-marker"
                  series={[
                    {
                      data: chartData.slice(1).map(([label, value]) => ({
                        id: label,
                        value,
                        label,
                      })),
                      innerRadius: 40,
                      outerRadius: 100,
                      paddingAngle: 1,
                      cornerRadius: 7,
                      startAngle: -90,
                      endAngle: 360,
                      highlightScope: {
                        faded: "global",
                        highlighted: "item",
                      },
                      faded: {
                        innerRadius: 30,
                        additionalRadius: -30,
                        color: "gray",
                      },
                    },
                  ]}
                  width={300}
                  height={200}
                />
              </div>

              <div className="row-md-4">
                <PieChart
                  series={[
                    {
                      data: chart2Data,
                      innerRadius: 40,
                      outerRadius: 100,
                      paddingAngle: 1,
                      cornerRadius: 5,
                      startAngle: -90,
                      endAngle: 360,
                      highlightScope: {
                        faded: "global",
                        highlighted: "item",
                      },
                      faded: {
                        innerRadius: 30,
                        additionalRadius: -30,
                        color: "gray",
                      },
                    },
                  ]}
                  width={300}
                  height={200}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AdminDetails;
