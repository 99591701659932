import React from "react";
import "./admin.css";
import { NavLink } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { FaHome, FaUsers, FaQuestion, FaInfo, FaFileAlt } from "react-icons/fa";

export default function AdminMenu() {
  return (
    <>
      <div className="admin-menu">
        <p className="menu-title">MENU</p>
        <List
          component="nav"
          aria-label="Menu"
          style={{
            fontFamily: "Roboto, san-serif",
            height: "100vh",
            backgroundColor: "white",
            color: "black",
            padding: "10px",
          }}
        >
          <ListItem component={NavLink} to="/admin">
            <ListItemIcon>
              <FaHome
                size={18}
                color="black"
                alt="dashboard"
                className="icon"
              />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem component={NavLink} to="/admin/groups">
            <ListItemIcon>
              <FaUsers
                size={18}
                color="black"
                alt="groups-logo"
                className="icon"
              />
            </ListItemIcon>
            <ListItemText primary="Groups" />
          </ListItem>
          <ListItem component={NavLink} to="/admin/quizzes">
            <ListItemIcon>
              <FaQuestion
                size={18}
                color="black"
                alt="quiz-logo"
                className="icon"
              />
            </ListItemIcon>
            <ListItemText primary="Quizzes" />
          </ListItem>
          <ListItem component={NavLink} to="/admin/general-info">
            <ListItemIcon>
              <FaInfo
                size={18}
                color="black"
                alt="info-logo"
                className="icon"
              />
            </ListItemIcon>
            <ListItemText primary="General Info" />
          </ListItem>
          <ListItem component={NavLink} to="/admin/jamaat-report">
            <ListItemIcon>
              <FaFileAlt
                size={18}
                color="black"
                alt="j-report-logo"
                className="icon"
              />
            </ListItemIcon>
            <ListItemText primary="Jammat Report" />
          </ListItem>
          <ListItem component={NavLink} to="/admin/marhala-report">
            <ListItemIcon>
              <FaFileAlt
                size={18}
                color="black"
                alt="m-report-logo"
                className="icon"
              />
            </ListItemIcon>
            <ListItemText primary="Marhala Report" />
          </ListItem>
        </List>
      </div>
    </>
  );
}
