import React, { useEffect } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  Box,
  Paper,
  TableRow,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
      backgroundColor: "#23C4ED33",
    },
  },
}))(TableRow);

export default function LeaderBoard(props) {
  const { common, getLeaderboard } = props;

  useEffect(() => {
    getLeaderboard(common.userDetails.its_id, props.match.params.id);
  }, [common.userDetails]);

  return (
    <>
      <Box m={3}>
        <TableContainer component={Paper}>
          <Box style={{ borderBottom: "1px solid gray" }}>
            <Typography
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                padding: 20,
              }}
            >
              {props.match.params.slug}
            </Typography>
          </Box>
          <Table aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Rank</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>User Name</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Jamat</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Jamiat</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Age</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {common.leaderboardData.map((user) => (
                <StyledTableRow key={user.name}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      color: user.rank < 1 ? "#bda420" : "inherit",
                      fontWeight: user.rank < 1 ? "bold" : "inherit",
                    }}
                  >
                    {user.rank < 1 ? "GOLD LEAGUE" : user.rank}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {user.name}
                  </TableCell>
                  <TableCell>{user.jamaat}</TableCell>
                  <TableCell>{user.jamiat}</TableCell>
                  <TableCell>{user.age}</TableCell>
                  <TableCell>{user.score}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
