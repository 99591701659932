import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getFrontQuizList,
  getLanguages
} from '../../actions/homeAction/homeAction'
import Quiz from "../../components/quiz/quiz";

export class QuizCont extends Component {
  render() {
    return <Quiz {...this.props} />;
  }
}

export const mapStateToProps = (store) => {
  return {
    common: store.common,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    getFrontQuizList: (user_id, list_id) => {
      dispatch(getFrontQuizList(user_id, list_id))
    },
    getLanguages: () => {
      dispatch(getLanguages())
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizCont);
