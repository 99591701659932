import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import LiquidFillGauge from "react-liquid-gauge";
import { interpolateRgb } from "d3-interpolate";
import { color } from "d3-color";
import Chart from "react-google-charts";
import { useSelector } from "react-redux";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 5,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#83588a",
  },
}))(LinearProgress);

const BorderLinearProgressGreen = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#3ac47d ",
  },
}))(LinearProgress);

const BorderLinearProgressRed = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#d92550 ",
  },
}))(LinearProgress);

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    padding: 25,
    [theme.breakpoints.down("sm")]: {
      padding: "0px 25px 25px 25px",
    },
  },
  marksOutOfGreen: {
    color: "#3ac47d",
    fontSize: "1.8rem",
    fontWeight: "700",
  },
  marksOutOfRed: {
    color: "#d92550",
    fontSize: "1.8rem",
    fontWeight: "700",
  },
  title: {
    fontSize: ".88rem",
    color: "#495057",
    fontWeight: "700",
  },
  description: {
    fontSize: ".88rem",
    color: "#495057",
    fontWeight: "400",
    opacity: "0.5",
  },
  barContainer: {
    padding: "10px 0px",
  },
  marksheetMarks: {
    fontSize: "1.6rem",
    color: "#6c757d",
    fontWeight: "700",
  },
  time: {
    color: "#495057",
    fontSize: ".88rem",
    fontWeight: "700",
  },
  percentText: {
    color: "#83588a",
    fontSize: "1.6rem",
    fontWeight: "700",
  },
  rankTitle: {
    fontSize: "1.6rem ",
    color: "#83588a",
    fontWeight: "700",
  },
  border: {
    height: 1,
    width: "100%",
    backgroundColor: "gray",
    opacity: 0.3,
  },
  noteText: {
    fontSize: 14,
    color: "gray",
  },
  markListContainer: {
    paddingLeft: 25,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingTop: 25,
    },
  },
}));

export default function Marksheet(props) {
  const classes = useStyle();

  const [chartData, setChartData] = useState([["Subject", "Obtain Marks"]]);

  const { common, getGroupData, getMarkList, getRank, getTotalMark } = props;

  useEffect(() => {
    if (common.userDetails.its_id) {
      getGroupData(props.match.params.id);
      getMarkList(common.userDetails.its_id, props.match.params.id);
      getRank(common.userDetails.its_id, props.match.params.id);
      getTotalMark(common.userDetails.its_id, props.match.params.id);
    }
  }, [common.userDetails]);

  useEffect(() => {
    common.markList.length > 0 &&
      chartData.length === 1 &&
      common.markList.map((mark) => {
        setChartData((prev) => [
          ...prev,
          [mark.quizTitle, parseInt(mark.obtainMarks)],
        ]);
      });
  }, [common.markList]);

  const radius = 80;
  const interpolate = interpolateRgb("#dc143c", "#6495ed");
  const fillColor = interpolate(80 / 100);
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];
  const [percente, setPercente] = useState(0);
  const percenteVal = parseInt(
    (common?.totalMark?.totalObtainMarks * 100) / common?.totalMark?.totalMarks
  );

  useEffect(() => {
    percenteVal && setPercente(percenteVal);
  }, [percenteVal]);

  const Marksheet = () => {
    const { groupData } = useSelector(({ common }) => common);
    return (
      <Card style={{ width: "100%", height: "100" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={`https://imtihaan2.talabulilm.com/images/${groupData.marksheet_header}`}
            style={{ width: "100%" }}
            alt="marksheet-logo"
          />
        </div>
        <CardContent style={{ padding: 25 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={3}>
              <Box>
                <Typography className={classes.marksheetMarks}>
                  {`${
                    common?.totalMark?.totalObtainMarks
                      ? common?.totalMark?.totalObtainMarks
                      : ""
                  }/${
                    common?.totalMark?.totalMarks
                      ? common?.totalMark?.totalMarks
                      : ""
                  }`}
                </Typography>
                <Typography className={classes.description}>Result</Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <LiquidFillGauge
                style={{ margin: "0 auto" }}
                width={radius}
                height={radius}
                value={percente}
                percent=""
                textSize={1}
                textOffsetX={0}
                textOffsetY={0}
                textRenderer={(props) => {
                  const value = Math.round(props.value);
                  const radius = Math.min(props.height / 2, props.width / 2);
                  const textPixels = (props.textSize * radius) / 2;
                  const valueStyle = {
                    fontSize: textPixels,
                  };
                  const percentStyle = {
                    fontSize: textPixels * 0.6,
                  };

                  return (
                    <tspan>
                      <tspan className="value" style={valueStyle}>
                        {percente}
                      </tspan>
                      <tspan style={percentStyle}>{props.percent}</tspan>
                    </tspan>
                  );
                }}
                riseAnimation
                waveAnimation
                waveFrequency={5}
                waveAmplitude={3}
                gradient
                gradientStops={gradientStops}
                circleStyle={{
                  fill: fillColor,
                }}
                waveStyle={{
                  fill: fillColor,
                }}
                textStyle={{
                  fill: color("#989fa4").toString(),
                }}
                waveTextStyle={{
                  fill: color("#fff").toString(),
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ margin: "25px 0px" }}
          >
            <Grid item xs={7} md={5}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography className={classes.percentText}>
                  {percente}%
                </Typography>
                <Box style={{ width: "100%", marginLeft: 10 }}>
                  <BorderLinearProgress
                    variant="determinate"
                    value={percente}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Grid container justifyContent="flex-end">
                <Grid item xs={9} md={12}>
                  <Box>
                    <Typography className={classes.time}>
                      {`${common.totalMark.TotalTimeSpent} Spent`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item xs={12} style={{ minHeight: 250 }}>
              {chartData.length > 1 && (
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="250px"
                  data={chartData}
                  options={{
                    colors: ["#FF6384"],
                    hAxis: {
                      minValue: 0,
                      maxValue: 5,
                      slantedText: true,
                      slantedTextAngle: 45,
                    },
                    vAxis: { minValue: 0, maxValue: 5 },
                    tooltip: { isHtml: true, trigger: "visible" },
                    chartArea: {
                      top: 28,
                      height: "40%",
                    },
                    legend: { position: "top" },
                  }}
                />
              )}
            </Grid>
          </Grid>
          <div className={classes.border}></div>
          <Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography className={classes.rankTitle}>
                Rank:{common.rank.rank}
              </Typography>
              <Typography>
                <Link
                  href={`/leaderboard/${props.match.params.id}/${props.match.params.slug}`}
                >
                  Leader Board
                </Link>
              </Typography>
            </Box>
            <Typography className={classes.noteText}>
              <b> Note:</b> Rank calculated based on marks obtained and time
              taken to complete the imtihaan.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  };

  const MarkList = (props) => {
    const {
      quizTitle,
      languageName,
      obtainMarks,
      totalMarks,
      Result,
      percent,
      TotalTimeSpent,
      viewLink,
    } = props;

    return (
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={9} md={10}>
              <Typography className={classes.title}>{quizTitle}</Typography>
              <Typography className={classes.description}>
                {languageName}
              </Typography>
            </Grid>
            <Grid item xs={3} md={2}>
              <Typography
                className={
                  Result == "1"
                    ? classes.marksOutOfGreen
                    : classes.marksOutOfRed
                }
              >
                {`${obtainMarks}/${totalMarks}`}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.barContainer}>
              {Result == "1" ? (
                <BorderLinearProgressGreen
                  variant="determinate"
                  value={percent}
                />
              ) : (
                <BorderLinearProgressRed
                  variant="determinate"
                  value={percent}
                />
              )}
            </Grid>
            <Grid item xs={8} md={9}>
              <Typography className={classes.description}>
                Completed In: {TotalTimeSpent}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              md={3}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {viewLink !== "" && (
                <Link
                  target="_blank"
                  href={viewLink}
                  className={classes.description}
                >
                  View Answer
                </Link>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };
  const { open } = useSelector(({ loader }) => loader);
  return (
    <div>
      <Grid container className={classes.mainContainer}>
        <Grid item xs={12} md={4}>
          {!open && <Marksheet />}
        </Grid>
        <Grid item xs={12} md={8} className={classes.markListContainer}>
          <Grid container spacing={3}>
            {common.markList.map((mark) => (
              <Grid item xs={12} md={6}>
                <MarkList {...mark} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
