import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
  Card,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  LinearProgress,
  Box,
  Link,
} from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CustomButton from "../UI/button";
import Certificate from "../../images/certificate.png";
import Scholar from "../../images/scholar.png";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Footer from "../footer";

var base64 = require("base-64");

const useStyles = makeStyles((theme) => ({
  paper: {
    height: 140,
    width: 450,
  },
  inActiveAccordion: {
    backgroundColor: "#457B9D",
    color: "#F1FAEE",
    textTransform: "uppercase",
    textDecoration: "none",
    minHeight: 60,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  inActiveAccordionDetails: {
    backgroundColor: "#F1FAEE",
    color: "#457B9D",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  inActiveAccordionButton: {
    borderRadius: 5,
    border: "1px solid #709395",
    textTransform: "none",
    fontWeight: "bold",
    textDecoration: "none",
    marginBottom: 10,
  },
  activeAccordion: {
    backgroundColor: "#E63946",
    color: "#F1FAEE",
    textTransform: "uppercase",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  activeAccordionDetails: {
    backgroundColor: "#FFFFFF",
    color: "#2F3F60",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  button: {
    textTransform: "none",
  },
  timelineList: {
    backgroundColor: "#A8DADC",
    border: "1px solid #457B9D",
    "&:hover": {
      backgroundColor: "#A8DADC",
    },
  },
  promotionCard: {
    margin: "20px 0px 20px 0px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  pb8: {
    paddingBottom: 8,
  },
  dark: {
    color: "#1E3456",
  },
  hideOtherCourse: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  showOtherCourse: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginBottom: 20,
    },
  },
  progressBorder: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    padding: 15,
    borderRadios: 4,
  },
  certImage: {
    width: 30,
    backgroundColor: "#fff",
    padding: 3,
    borderRadius: 3,
  },
  paddingGrid: {
    maxWidth: "100%",
    paddingBottom: 16,
    marginTop: 16,
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

export default function Home(props) {
  const {
    common,
    getCertificates,
    getTimelines,
    getGroupListFront,
    getGroupInfo,
    getFrontPromotionList,
  } = props;

  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    localStorage.setItem(
      "authToken",
      base64.encode(common.userDetails.its_id + ":" + common.userVer)
    );
    if (common.userDetails !== "") {
      getCertificates(common.userDetails.its_id);
      getTimelines({
        user_id: common.userDetails.its_id,
        ver: common.userVer,
      });
      getGroupListFront({
        user_id: common.userDetails.its_id,
        ver: common.userVer,
      });
      getGroupInfo({
        label: "groups",
        user_id: common.userDetails.its_id,
        ver: common.userVer,
      });
      getFrontPromotionList({
        user_id: common.userDetails.its_id,
        ver: common.userVer,
      });
    }
  }, [common.userDetails]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function LinearProgressWithLabel(props) {
    return (
      <Box
        display="flex"
        alignItems="center"
        className={classes.progressBorder}
      >
        <Box width="100%" style={{ width: 400 }} mr={1}>
          {props.value ? (
            <LinearProgress
              variant="determinate"
              value={Math.round(props.value)}
              {...props}
            />
          ) : (
            ""
          )}
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <div>
      <Grid container spacing={2} className={classes.paddingGrid}>
        {/* First row */}
        <Grid item xs={12} md={8}>
          {/* Promotional box */}
          <div className={classes.promotionCard}>
            {common.frontPromotionList &&
              common.frontPromotionList.map((item, index) => {
                return (
                  <Accordion elevation={5} expanded={true} key={index}>
                    <AccordionSummary
                      className={classes.activeAccordion}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography className={classes.heading}>
                          {item.name}
                        </Typography>
                        <div style={{ marginLeft: 10, display: "flex" }}>
                          {Number(item.show_marksheet) === 1 && (
                            <Link
                              target="_blank"
                              href={`https://imtihaan.talabulilm.com/marksheet/${item.id}/${item.slug}`}
                            >
                              <img
                                style={{ padding: " 0px 10px" }}
                                src={Certificate}
                                alt="Badge"
                              />
                            </Link>
                          )}
                          {item.certificate_id > 0 &&
                            item.certificate_status > 0 && (
                              <Link
                                target="_blank"
                                href={`https://www.talabulilm.com/istifaadah/certificate.php?cid=${item.certificate_id}`}
                              >
                                <img src={Scholar} alt="Badge" />
                              </Link>
                            )}
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      className={classes.activeAccordionDetails}
                    >
                      <div
                        style={{ minHeight: 180 }}
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                    </AccordionDetails>
                    <AccordionActions
                      className={classes.activeAccordionDetails}
                    >
                      {item.btn_status === "Closed" && (
                        <CustomButton
                          disabled
                          variant="contained"
                          color="secondary"
                          className={classes.inActiveAccordionButton}
                        >
                          Ended
                        </CustomButton>
                      )}
                      {item.btn_status === "Open" && (
                        <Link href={`/quizzes/${item.id}/${item.slug}`}>
                          <CustomButton
                            variant="contained"
                            color="primary"
                            className={classes.inActiveAccordionButton}
                          >
                            Attempt
                          </CustomButton>
                        </Link>
                      )}
                      {item.btn_status === "Will Start" && (
                        <CustomButton
                          disabled
                          variant="contained"
                          color="secondary"
                          className={classes.inActiveAccordionButton}
                        >
                          Coming soon
                        </CustomButton>
                      )}
                    </AccordionActions>
                  </Accordion>
                );
              })}
          </div>

          <Grid container spacing={2}>
            {/* Other courses */}
            <Grid item md={6} className={classes.hideOtherCourse}>
              {common.groupListFront.length > 0 &&
                common.groupListFront.map((group, index) => {
                  return (
                    <Accordion
                      key={index}
                      style={{
                        borderTopLeftRadius: index === 0 && 4,
                        borderTopRightRadius: index === 0 && 4,
                        borderBottomLeftRadius:
                          index === common.groupListFront.length - 1 &&
                          expanded !== `panel${index}` &&
                          4,
                        borderBottomRightRadius:
                          index === common.groupListFront.length - 1 &&
                          expanded !== `panel${index}` &&
                          4,
                      }}
                      expanded={expanded === `panel${index}`}
                      onChange={handleChange(`panel${index}`)}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon style={{ color: "#fff" }} />
                        }
                        className={classes.inActiveAccordion}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{
                          borderTopLeftRadius: index === 0 && 4,
                          borderTopRightRadius: index === 0 && 4,
                          borderBottomLeftRadius:
                            index === common.groupListFront.length - 1 &&
                            expanded !== `panel${index}` &&
                            4,
                          borderBottomRightRadius:
                            index === common.groupListFront.length - 1 &&
                            expanded !== `panel${index}` &&
                            4,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography className={classes.heading}>
                            {group.name}
                          </Typography>
                          <div style={{ marginLeft: 10, display: "flex" }}>
                            {group.show_marksheet === "1" && (
                              <Link
                                target="_blank"
                                href={`https://imtihaan.talabulilm.com/marksheet/${group.id}/${group.slug}`}
                              >
                                <img
                                  style={{ padding: " 0px 10px" }}
                                  src={Certificate}
                                  alt="Badge"
                                />
                              </Link>
                            )}
                            {group.certificate_id > 0 &&
                              group.certificate_status > 0 && (
                                <Link
                                  target="_blank"
                                  href={`https://www.talabulilm.com/istifaadah/certificate.php?cid=${group.certificate_id}`}
                                >
                                  <img src={Scholar} alt="Badge" />
                                </Link>
                              )}
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails
                        className={classes.inActiveAccordionDetails}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: group.description,
                          }}
                        />
                      </AccordionDetails>
                      <AccordionActions
                        className={classes.inActiveAccordionDetails}
                      >
                        {group.btn_status === "Closed" && (
                          <CustomButton
                            disabled
                            variant="contained"
                            color="secondary"
                            className={classes.inActiveAccordionButton}
                          >
                            Ended
                          </CustomButton>
                        )}
                        {group.btn_status === "Open" && (
                          <Link to={`/quizzes/${group.id}/${group.slug}`}>
                            <CustomButton
                              variant="contained"
                              color="secondary"
                              className={classes.inActiveAccordionButton}
                            >
                              Attempt
                            </CustomButton>
                          </Link>
                        )}
                        {group.btn_status === "Will Start" && (
                          <CustomButton
                            disabled
                            variant="contained"
                            color="secondary"
                            className={classes.inActiveAccordionButton}
                          >
                            Coming soon
                          </CustomButton>
                        )}
                      </AccordionActions>
                    </Accordion>
                  );
                })}
            </Grid>

            {/* My certificates Desktop */}
            <Grid item md={6} className={classes.hideOtherCourse}>
              <Card style={{ width: "100%", backgroundColor: "#457B9D" }}>
                <Typography
                  variant="h6"
                  style={{ padding: 20, color: "#F1FAEE" }}
                >
                  My Certificates
                </Typography>
              </Card>
              <List
                style={{ marginTop: "-10px", paddingBottom: 0 }}
                component="nav"
                aria-label="secondary mailbox folders"
              >
                {common.certificates.length > 0 &&
                  common.certificates.map((cert, index) => {
                    return (
                      <ListItem
                        key={index}
                        style={{
                          borderBottomLeftRadius:
                            common.certificates.length - 1 === index && 4,
                          borderBottomRightRadius:
                            common.certificates.length - 1 === index && 4,
                        }}
                        button
                        component={"a"}
                        href={`https://www.talabulilm.com/istifaadah/certificate.php?cid=${cert.id}`}
                        target="_blank"
                        className={classes.timelineList}
                      >
                        <ListItemIcon>
                          <img
                            className={classes.certImage}
                            src={`https://www.talabulilm.com/istifaadah/upload/courses/certificate/${cert.icon}`}
                            alt="Badge"
                          />
                        </ListItemIcon>
                        <ListItemText primary={cert.title} />
                      </ListItem>
                    );
                  })}
              </List>
            </Grid>
          </Grid>
        </Grid>

        {/* second row */}
        <Grid item xs={12} md={4}>
          {/* Info box */}
          {common.groupInfo.text && common.groupInfo.text !== "" && (
            <div style={{ margin: "20px 0px 0px 0px" }}>
              <Accordion elevation={5} expanded={true}>
                <AccordionSummary
                  className={classes.inActiveAccordion}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    IMPORTANT INSTRUCTIONS
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.inActiveAccordionDetails}>
                  <div
                    dangerouslySetInnerHTML={{ __html: common.groupInfo.text }}
                  ></div>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
          {/* Profile details */}
          <div style={{ margin: "20px 0px 20px 0px" }}>
            <Accordion elevation={5} expanded={true}>
              <AccordionSummary
                className={classes.activeAccordion}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  My Educational Journey
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{ flexDirection: "column" }}
                className={classes.activeAccordionDetails}
              >
                <div
                  style={{ minHeight: 130 }}
                  dangerouslySetInnerHTML={{
                    __html: common.timelines.education_status,
                  }}
                />
                <LinearProgressWithLabel
                  color="secondary"
                  value={common.timelines.percent_complete}
                />
              </AccordionDetails>
              <AccordionActions className={classes.activeAccordionDetails}>
                <CustomButton
                  target="_blank"
                  href="https://www.talabulilm.com/profile/"
                  variant="contained"
                  color="primary"
                  className={classes.inActiveAccordionButton}
                >
                  Profile
                </CustomButton>
              </AccordionActions>
            </Accordion>
          </div>

          {/* Timeline list */}
          <div>
            <Card
              style={{
                width: "100%",
                backgroundColor: "#457B9D",
                paddingBottom: 0,
              }}
            >
              <Typography
                variant="h6"
                style={{ padding: 20, color: "#F1FAEE" }}
              >
                My Education Timeline
              </Typography>
            </Card>
            <List
              style={{ marginTop: "-10px", paddingBottom: 0 }}
              component="nav"
              aria-label="secondary mailbox folders"
            >
              {common.timelines &&
                common.timelines.timeline &&
                common.timelines.timeline.map((tm, index) => {
                  return (
                    <ListItem
                      key={index}
                      style={{
                        backgroundColor: tm.status === "Pending" && "#b4d0d1",
                        borderBottomLeftRadius:
                          common.timelines.timeline.length - 1 === index && 4,
                        borderBottomRightRadius:
                          common.timelines.timeline.length - 1 === index && 4,
                      }}
                      button={tm.status === "Pending"}
                      component={tm.status === "Pending" ? "a" : ""}
                      target="_blank"
                      href="https://www.talabulilm.com/profile/"
                      className={classes.timelineList}
                    >
                      <ListItemIcon>
                        {tm.status === "Pending" ? (
                          <ClearIcon style={{ color: "#7D3424" }} />
                        ) : (
                          <CheckIcon style={{ color: "#247D47" }} />
                        )}
                      </ListItemIcon>
                      {tm.status !== "Pending" ? (
                        <ListItemText
                          primary={tm.marhala_text}
                          secondary={`${tm.status}`}
                          className={classes.dark}
                        />
                      ) : (
                        <ListItemText
                          primary={tm.marhala_text}
                          secondary="Click here to update your education details"
                        />
                      )}
                    </ListItem>
                  );
                })}
            </List>
          </div>
        </Grid>

        {/* Third row */}
        <Grid item xs={12} md={4}>
          {/* My certificates Mobile */}
          <div
            className={classes.showOtherCourse}
            style={{ margin: "20px 0px 20px 0px" }}
          >
            <Card style={{ width: "100%", backgroundColor: "#457B9D" }}>
              <Typography
                variant="h6"
                style={{ padding: 20, color: "#F1FAEE" }}
              >
                My Certificates
              </Typography>
            </Card>
            <List
              style={{ marginTop: "-10px", paddingBottom: 0 }}
              component="nav"
              aria-label="secondary mailbox folders"
            >
              {common.certificates.length > 0 &&
                common.certificates.map((cert, index) => {
                  return (
                    <ListItem
                      key={index}
                      style={{
                        borderBottomLeftRadius:
                          common.certificates.length - 1 === index && 4,
                        borderBottomRightRadius:
                          common.certificates.length - 1 === index && 4,
                      }}
                      button
                      component={"a"}
                      href={`https://www.talabulilm.com/istifaadah/certificate.php?cid=${cert.id}`}
                      target="_blank"
                      className={classes.timelineList}
                    >
                      <ListItemIcon>
                        <img
                          className={classes.certImage}
                          src={`https://www.talabulilm.com/istifaadah/upload/courses/certificate/${cert.icon}`}
                          alt="Badge"
                        />
                      </ListItemIcon>
                      <ListItemText primary={cert.title} />
                    </ListItem>
                  );
                })}
            </List>
          </div>

          {/* Other courses mobile */}
          <div className={classes.showOtherCourse}>
            {common.groupListFront.length > 0 &&
              common.groupListFront.map((group, index) => {
                return (
                  <Accordion
                    key={index}
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                    style={{
                      borderTopLeftRadius: index === 0 && 4,
                      borderTopRightRadius: index === 0 && 4,
                      borderBottomLeftRadius:
                        index === common.groupListFront.length - 1 &&
                        expanded !== `panel${index}` &&
                        4,
                      borderBottomRightRadius:
                        index === common.groupListFront.length - 1 &&
                        expanded !== `panel${index}` &&
                        4,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                      className={classes.inActiveAccordion}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{
                        borderTopLeftRadius: index === 0 && 4,
                        borderTopRightRadius: index === 0 && 4,
                        borderBottomLeftRadius:
                          index === common.groupListFront.length - 1 &&
                          expanded !== `panel${index}` &&
                          4,
                        borderBottomRightRadius:
                          index === common.groupListFront.length - 1 &&
                          expanded !== `panel${index}` &&
                          4,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography className={classes.heading}>
                          {group.name}
                        </Typography>
                        <div style={{ marginLeft: 10, display: "flex" }}>
                          {group.show_marksheet === "1" && (
                            <Link
                              target="_blank"
                              href={`https://imtihaan.talabulilm.com/marksheet/${group.id}/${group.slug}`}
                            >
                              <img src={Certificate} alt="Badge" />
                            </Link>
                          )}
                          {group.certificate_id > 0 &&
                            group.certificate_status > 0 && (
                              <Link
                                target="_blank"
                                href={`https://www.talabulilm.com/istifaadah/certificate.php?cid=${group.certificate_id}`}
                              >
                                <img src={Scholar} alt="Badge" />
                              </Link>
                            )}
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      className={classes.inActiveAccordionDetails}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: group.description }}
                      />
                    </AccordionDetails>
                    <AccordionActions
                      className={classes.inActiveAccordionDetails}
                    >
                      {group.btn_status === "Closed" && (
                        <CustomButton
                          disabled
                          variant="contained"
                          color="secondary"
                          className={classes.inActiveAccordionButton}
                        >
                          Ended
                        </CustomButton>
                      )}
                      {group.btn_status === "Open" && (
                        <Link to={`/quizzes/${group.id}/${group.slug}`}>
                          <CustomButton
                            variant="contained"
                            color="secondary"
                            className={classes.inActiveAccordionButton}
                          >
                            Attempt
                          </CustomButton>
                        </Link>
                      )}
                      {group.btn_status === "Will Start" && (
                        <CustomButton
                          disabled
                          variant="contained"
                          color="secondary"
                          className={classes.inActiveAccordionButton}
                        >
                          Coming soon
                        </CustomButton>
                      )}
                    </AccordionActions>
                  </Accordion>
                );
              })}
          </div>
        </Grid>
      </Grid>
      {/* <iframe
        src={'sample.pdf'}
        height={854}
        width={"100%"}
      /> */}
      <Footer />
    </div>
  );
}
