export const SET_TIMELINES = "SET_TIMELINES";
export const SET_CERTIFICATES = "SET_CERTIFICATES";
export const SET_GROUP_LIST_FRONT = "SET_GROUP_LIST_FRONT";
export const SET_GROUP_INFO = "SET_GROUP_INFO";
export const SET_QUIZ_INFO = "SET_QUIZ_INFO";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_FRONT_QUIZ_LIST = "SET_FRONT_QUIZ_LIST";
export const SET_FRONT_PROMOTION_LIST = "SET_FRONT_PROMOTION_LIST";

export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_VER = "SET_VER";
export const SET_MARK_LIST = "SET_MARK_LIST";
export const SET_TOTAL_MARK = "SET_TOTAL_MARK";
export const SET_GROUP_DATA = "SET_GROUP_DATA";
export const SET_RANK = "SET_RANK";

export const SET_LEADERBOARD_DATA = "SET_LEADERBOARD_DATA";