import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Dialog,
  Slide,
  Button,
} from "@mui/material";
import AdminMenu from "../AdminMenu";
import { FaDownload, FaUpload, FaTrash, FaMinus, FaPen } from "react-icons/fa";
import { ThreeDots } from "react-loader-spinner";
import QuizAddForm from "./QuizAddForm";
import QuizEditForm from "./QuizEditForm";
import { QuizUploadForm } from "./QuizUploadForm";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const authToken = localStorage.getItem("authToken");
const columns = [
  { id: "name", label: "Title" },
  { id: "language", label: "Language" },
  { id: "folder_name", label: "Folder Name" },
  { id: "group_name", label: "Group Name" },
  { id: "start_date", label: "Start Date" },
  { id: "end_date", label: "End Date" },
  { id: "is_active", label: "Status" },
  { id: "action", label: "Action" },
];

function QuizTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://imtihaan.talabulilm.com/imtihaan/user/quiz_list/0/all",
          {
            headers: {
              Authorization: "Basic " + authToken,
            },
          }
        );
        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // --------------CSV-----------------------------
  const downloadCsv = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      data.map((row) => Object.values(row).join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "jamaat-report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  // --------------Filter-----------------------------
  const [filteredData, setFilteredData] = useState([]);

  const handleFilterChange = (event, columnId) => {
    const inputValue = event.target.value.toLowerCase();
    const filteredRows = data.filter((row) =>
      row[columnId].toLowerCase().includes(inputValue)
    );
    setFilteredData(filteredRows);
  };

  //Form---------------------------------------------
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("lg");

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //--------------------Upload Open----------------------------------
  const [openUpload, setOpenUpload] = React.useState(false);

  // const [selectRowId, setSelectSetRowId] = React.useState("");
  const handleClickOpenUpload = async (id) => {
    setSelectSetRowId(id);
    try {
      const response = await axios.get(
        `https://imtihaan.talabulilm.com/imtihaan/admin/quiz_data/${id}`,
        {
          headers: {
            Authorization: "Basic " + authToken,
          },
        }
      );
      setEditData(response.data.data);
      setOpenUpload(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error
    }
  };

  const handleCloseUpload = () => {
    setOpenUpload(false);
    setSelectSetRowId(""); // Clear the selected row ID
  };

  //---------------Delete Operation------------------------------------
  const handleClickDelete = async (rowId) => {
    const result = window.confirm(
      "Are you sure you want to delete this Folder?"
    );
    if (result) {
      try {
        const response = await axios.delete(
          `https://imtihaan.talabulilm.com/imtihaan/admin/delete/quiz/id/${rowId}`,
          {
            headers: {
              Authorization: "Basic " + authToken,
            },
          }
        );
        if (response.status === 200) {
          toast.success("Item deleted successfully!");
          // console.log("Delete Successfull");
        } else {
          // console.error("Failed to delete item.");
          toast.error("Failed to delete item.");
        }
      } catch (error) {
        toast.error("Error deleting item");
        // console.error("Error deleting item:", error);
      }
    } else {
      // console.log("Delete operation cancelled!");
      toast.error("Delete operation cancelled!");
    }
  };
  //---------------Delete Folder Operation------------------------------------
  const handleClickDeleteFolder = async (rowId) => {
    const result = window.confirm("Are you sure you want to delete this item?");
    if (result) {
      try {
        const response = await axios.delete(
          `https://talabulilm.com/imtihaan/imtihaan-api/api/delete/folder/${rowId}`
        );
        if (response.status === 200) {
          toast.success("Folder deleted successfully!");
          // console.log("Delete Successfull");
        } else {
          // console.error("Failed to delete folder.");
          toast.error("Failed to delete folder.");
        }
      } catch (error) {
        toast.error("Error deleting item");
        // console.error("Error deleting item:", error);
      }
    } else {
      // console.log("Delete operation cancelled!");
      toast.error("Delete operation cancelled!");
    }
  };
  //Edit Open------------------------------------
  const [openEdit, setOpenEdit] = React.useState(false);

  const [selectRowId, setSelectSetRowId] = React.useState("");

  const handleClickOpenEdit = async (id) => {
    setSelectSetRowId(id);
    try {
      const response = await axios.get(
        `https://imtihaan.talabulilm.com/imtihaan/admin/quiz_data/${id}`,
        {
          headers: {
            Authorization: "Basic " + authToken,
          },
        }
      );
      setEditData(response.data.data);
      setOpenEdit(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error
    }
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
    setSelectSetRowId(""); // Clear the selected row ID
  };
  //------------------Edit Form Data-------------------------------

  const [editData, setEditData] = useState([]);
  useEffect(() => {
    if (openEdit && selectRowId) {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `https://imtihaan.talabulilm.com/imtihaan/admin/quiz_data/${selectRowId}`,
            {
              headers: {
                Authorization: "Basic " + authToken,
              },
            }
          );
          setEditData(response.data.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [selectRowId, openEdit]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        PaperProps={{ style: { maxHeight: "80vh", minHeight: "50vh" } }}
      >
        <QuizAddForm handleClose={handleClose} />
      </Dialog>

      <Dialog
        open={openEdit}
        onClose={handleCloseEdit}
        TransitionComponent={Transition}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        PaperProps={{ style: { maxHeight: "80vh", minHeight: "50vh" } }}
      >
        <QuizEditForm
          handleCloseEdit={handleCloseEdit}
          selectRowId={selectRowId}
          editData={editData}
        />
      </Dialog>

      <Dialog
        open={openUpload}
        onClose={handleCloseUpload}
        TransitionComponent={Transition}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        PaperProps={{ style: { maxHeight: "50vh", minHeight: "30vh" } }}
      >
        <QuizUploadForm
          handleCloseUpload={handleCloseUpload}
          selectRowId={selectRowId}
          editData={editData}
        />
      </Dialog>

      <div className="container-admin" style={{ marginTop: "20px" }}>
        <AdminMenu />
        <Paper
          sx={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            marginLeft: "10px",
          }}
        >
          <div>
            <span
              style={{
                opacity: "0.8",
                margin: "20px",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {" "}
              Quiz List
              <Button variant="outlined" onClick={handleClickOpen}>
                + Add Quizzes
              </Button>
            </span>
            <hr className="underline"></hr>
          </div>
          {data.length === 0 ? (
            <span
              style={{
                display: "block",
                width: "50px",
                margin: "auto",
                position: "inline",
                textAlign: "center",
              }}
            >
              <ThreeDots color="darkblue" size={50} />
            </span>
          ) : (
            <>
              <span
                onClick={downloadCsv}
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "20px",
                  marginTop: "10px",
                  color: "green",
                }}
              >
                <FaDownload size={15} color="green" /> Download Csv
              </span>
              <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align="left"
                          style={{
                            minWidth: 150,
                            fontSize: "19px",
                            color: "blue",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.id} align="left">
                          <TextField
                            id={column.id + "-filter"}
                            label={`Filter ${column.label}`}
                            variant="outlined"
                            size="small"
                            onChange={(event) =>
                              handleFilterChange(event, column.id)
                            }
                            style={{
                              marginLeft: "10px",
                              borderBottom: "1px solid red",
                            }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(filteredData.length > 0 ? filteredData : data)
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const totalUser =
                          parseInt(row.totalFemale) + parseInt(row.totalMale);
                        const action = (
                          <>
                            <FaPen
                              size={17}
                              color="green"
                              alt="eyelogo"
                              className="icon"
                              style={{ marginRight: "10px" }}
                              onClick={() => handleClickOpenEdit(row.id)}
                            />
                            <FaTrash
                              size={17}
                              color="red"
                              alt="eyelogo"
                              className="icon"
                              onClick={() => handleClickDelete(row.id)}
                              style={{ marginRight: "10px" }}
                            />
                            <FaUpload
                              size={17}
                              alt="eyelogo"
                              className="icon"
                              style={{ marginRight: "10px" }}
                              onClick={() => handleClickOpenUpload(row.id)}
                            />
                            <FaMinus
                              size={17}
                              alt="eyelogo"
                              className="icon"
                              onClick={() => handleClickDeleteFolder(row.id)}
                            />
                          </>
                        );
                        return (
                          <TableRow hover key={index}>
                            <TableCell align="left" style={{ padding: "10px" }}>
                              {row.name}
                            </TableCell>
                            <TableCell align="left">{row.language}</TableCell>
                            <TableCell align="left">
                              {row.folder_name}
                            </TableCell>
                            <TableCell align="left">{row.group_name}</TableCell>
                            <TableCell align="left">{row.start_date}</TableCell>
                            <TableCell align="left">{row.end_date}</TableCell>
                            <TableCell align="left">
                              {row.is_active === 1 ? "InActive" : "Active"}
                            </TableCell>
                            <TableCell align="left">{action}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="span"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) => {
                  return (
                    <span
                      style={{
                        color: "blue",
                        fontWeight: "bold",
                        display: "flex",
                      }}
                    >
                      {`${from}-${to} of ${count}`}
                    </span>
                  );
                }}
                sx={{
                  margin: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  "& .MuiButtonBase-root": {
                    borderRadius: "20px",
                    padding: "10px",
                    marginRight: "10px",
                  },
                  "& .MuiSelect-selectMenu": {
                    color: "red",
                    paddingRight: "10px",
                  },
                  "& .MuiTablePagination-actions button": {
                    backgroundColor: "#545cd8",
                    gap: "10px",
                    color: "white",
                    borderRadius: "5px",
                    padding: "8px",
                  },
                }}
              />
            </>
          )}
        </Paper>
      </div>
      <ToastContainer />
    </>
  );
}
export default QuizTable;
