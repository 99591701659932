import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomeCont from "../../containers/homeCont/homeCont";
import LeaderBoardCont from "../../containers/leaderBoardCont/leaderBoardCont";
import MarksheetCont from "../../containers/marksheetCont/marksheetCont";
import QuizCont from "../../containers/quizCont/quizCont";
import GeneralInfo from "../AdminPages/General Info/GeneralInfo.jsx";
import Groups from "../AdminPages/Groups/Groups";
import JammatReport from "../AdminPages/JamaatReport/JammatReport";
import QuizTable from "../AdminPages/Quizzes/QuizTable.jsx";
import Admin from "../AdminPages/admin";
import ControllerCont from "./../../containers/controllerCont/controllerCont";
import LoaderCont from "./../../containers/loader/loaderCont";
import CustomAppBar from "./../appBar/appBar";
import MarhalaReport from "../AdminPages/MarhalaReport/MarhalaReport.jsx";
import PaperWisePage from "../AdminPages/DashBoard/PaperWisePage.jsx";

export default function Router(props) {
  return (
    <BrowserRouter>
      <div>
        <CustomAppBar {...props} />
        <LoaderCont />

        <Route path="/" component={ControllerCont} />
        <Route exact path="/" component={HomeCont} />
        <Route exact path="/quizzes/:id/:slug" component={QuizCont} />
        <Route exact path="/marksheet/:id/:slug" component={MarksheetCont} />
        <Route
          exact
          path="/leaderboard/:id/:slug"
          component={LeaderBoardCont}
        />
        <Switch>
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/admin/jamaat-report" component={JammatReport} />
          <Route exact path="/admin/groups" component={Groups} />
          <Route exact path="/admin/quizzes" component={QuizTable} />
          <Route exact path="/admin/general-info" component={GeneralInfo} />
          <Route exact path="/admin/marhala-report" component={MarhalaReport} />
          <Route
            exact
            path="/admin/paperwise-details/:quizId"
            component={PaperWisePage}
          />
        </Switch>
      </div>
    </BrowserRouter>
  );
}
