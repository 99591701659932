import { Button } from "@material-ui/core";
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import React from "react";

const greenTheme = createTheme({
  palette: {
    primary: { main: "#E63946" },
    secondary: { main: "#A8DADC" }
  },
  overrides: {
    MuiButton: {
      containedSecondary: {
        color: "#2F3F60"
      },
      containedPrimary: {
        color: "#ffffff"
      },
      textPrimary: {
        "&:hover": {
          backgroundColor: "#E63946"
        }
      },
      textSecondary: {
        "&:hover": {
          backgroundColor: "#A8DADC"
        }
      }
    }
  }
})


export default class CustomButton extends React.Component {
  render() {
    return (
      <>
        <MuiThemeProvider theme={greenTheme}>
          <Button {...this.props} />
        </MuiThemeProvider>
      </>
    )
  }
}

