import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getGroupData,
  getMarkList,
  getRank,
  getTotalMark
} from '../../actions/marksheetAction/marksheetAction'
import Marksheet from './../../components/marksheet/marksheet';

export class MarksheetCont extends Component {
  render() {
    return <Marksheet {...this.props} />;
  }
}

export const mapStateToProps = (store) => {
  return {
    common: store.common,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    getGroupData: (id) => {
      dispatch(getGroupData(id))
    },
    getMarkList: (user_id, id) => {
      dispatch(getMarkList(user_id, id))
    },
    getRank: (user_id, id) => {
      dispatch(getRank(user_id, id))
    },
    getTotalMark: (user_id, id) => {
      dispatch(getTotalMark(user_id, id))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MarksheetCont);
