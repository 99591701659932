import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { ThreeDots } from "react-loader-spinner";
import PaperWiseTable from "./PaperWiseTable";
import DropdownOptions from "./DropDown";
import TableComponent from "./TableComponent";
import AdminDetails from "./AdminDetails";
import AdminMenu from "../AdminMenu";

const Dashboard = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [apiData, setApiData] = useState(null);

  const [itsId, setItsId] = useState([]);
  const [urlLast, setUrlLast] = useState("");
  const [report, setReport] = useState("");
  const baseUrl = `https://imtihaan.talabulilm.com/imtihaan/admin/`;
  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    let isMounted = true;
    let lastPart = null;

    const fetchData = async () => {
      try {
        if (selectedValue) {
          const apiUrl = `${baseUrl}user_list/all/${selectedValue}/all`;

          const parts = apiUrl.split("/");
          lastPart = parts[parts.length - 2];

          setUrlLast(lastPart);
          let myHeaders = new Headers();
          myHeaders.append("Authorization", "Basic " + authToken);
          let requestOptions = {
            method: "GET",
            headers: myHeaders,
          };

          const response1 = await fetch(apiUrl, requestOptions);
          const data1 = await response1.json();

          if (isMounted) {
            setApiData(data1);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [selectedValue, urlLast]);

  const handleEyeIconClick = (row) => {
    if (row && row.original && row.original.its_id) {
      setItsId(row.original.its_id);

      const reportUrl = `/marksheet/${urlLast}/${row.original.its_id}`;

      setReport(reportUrl);
    }
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  // console.log(report)

  const columns = [
    { Header: "No.", accessor: (row, index) => index + 1, id: "indexColumnId" },
    { Header: "Username", accessor: "name", id: "nameColumnId" },
    { Header: "Jammat", accessor: "jamaat", id: "jammatColumnId" },
    { Header: "Jamiat", accessor: "jamiat", id: "jamiatColumnId" },
    { Header: "Country", accessor: "country", id: "countryColumnId" },
    { Header: "City", accessor: "city", id: "cityColumnId" },
    { Header: "", accessor: "empty" },
    { Header: "P.no.", accessor: "phone", id: "phoneColumnId" },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <a href={report} target="_blank">
          <FaEye
            size={18}
            color="blue"
            alt="eyelogo"
            className="icon"
            onClick={() => handleEyeIconClick(row)}
          />
        </a>
      ),
    },
  ];

  const columns2 = [
    {
      Header: "Quizz Name",
      accessor: "quizName",
      id: "nameQuizzNameId",
    },
    { Header: "Total Users", accessor: "totalusers", id: "nameTotalUsersId" },
    {
      Header: "Passed Users",
      accessor: "totalPassedUser",
      id: "namePassedUsersId",
    },
    {
      Header: "Failed Users",
      accessor: "totalFailedUser",
      id: "nameFailedUsersId",
    },
    {
      Header: "100% Score",
      accessor: "total100PercScoreCount",
      id: "nameScoreId",
    },
    { Header: "Avg Time", accessor: "avgTime", id: "nameAvgTimeId" },
    {
      Header: "Actions",
      accessor: "",
      id: "nameActionsId",
    },
  ];

  return (
    <>
      <div className="container-admin" style={{ marginTop: "10px" }}>
        <AdminMenu />
        <div className="container-table" style={{ marginLeft: "10px" }}>
          <DropdownOptions
            selectedValue={selectedValue}
            handleSelectChange={handleSelectChange}
          />
          <AdminDetails baseUrl={baseUrl} lastUrl={urlLast} />
          <PaperWiseTable
            baseUrl={baseUrl}
            lastUrl={urlLast}
            columns2={columns2}
            report={report}
            handleEyeIconClick={handleEyeIconClick}
          />

          <div className="admin-table ">
            <>
              <h4
                style={{
                  fontSize: "1em",
                  margin: "15px",
                  fontWeight: "bold",
                  color: "rgba(18,21,78,.7)",
                }}
              >
                USER LIST
              </h4>
              <hr className="underline" />
              <TableComponent
                columns={columns}
                data={
                  apiData?.data.map((row) => ({ ...row, id: row.its_id })) || []
                }
              />
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
