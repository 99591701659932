import React, { useEffect, useState } from "react";
import { Select, MenuItem, FormControl } from "@material-ui/core";

const DropdownOptions = ({ handleSelectChange }) => {
  const [drop, setDrop] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic " + authToken);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(
      "https://imtihaan.talabulilm.com/imtihaan/user/group_list",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setDrop(data.data);
        if (data.data.length > 0) {
          // If no selected value is set, select the first item
          if (!selectedValue) {
            setSelectedValue(`${data.data[0].id}`);
            handleSelectChange({
              target: {
                value: `${data.data[0].id}`,
              },
            });
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching options:", error);
      });
  }, [selectedValue]);
  // console.log(selectedValue, "selcb");
  const handleDropdownChange = (event) => {
    const { value } = event.target;

    setSelectedValue(value);
    handleSelectChange({
      target: {
        value: value,
      },
    });
  };

  // useEffect(() => {
  //   fetch("https://talabulilm.com/imtihaan/imtihaan-api/api/get/group_list")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setDrop(data.data);
  //       if (data.data.length > 0) {
  //         if (!selectedValue) {
  //           setSelectedValue(`/${data.data[0].id}/all`);
  //           handleSelectChange({
  //             target: { value: `/${data.data[0].id}/all` },
  //             id: data.data[0].id,
  //           });
  //         } else {
  //           // Ensure the selected value is synced with the drop-down options
  //           const selectedOption = data.data.find(
  //             (option) => `/${option.id}/all` === selectedValue
  //           );
  //           if (selectedOption) {
  //             handleSelectChange({
  //               target: { value: `/${data.data[0].id}/all` },
  //               id: selectedOption.id,
  //             });
  //           }
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching options:", error);
  //     });
  // }, [handleSelectChange, selectedValue]);

  // const handleDropdownChange = (event) => {
  //   const { value, id } = event.target;
  //   setSelectedValue(value);
  //   setSelectedId(id);
  //   const { id: group_id } = event.target;
  //   handleSelectChange(group_id);
  // };

  return (
    <FormControl
      sx={{ m: 1 }}
      variant="standard"
      name="DropDownMenu"
      id="DropDownMenu"
    >
      <Select
        value={selectedValue}
        onChange={handleDropdownChange}
        className="search-bar"
        size="x-large"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        {drop.map((menuItem) => (
          <MenuItem name={menuItem.name} value={menuItem.id} key={menuItem.id}>
            {menuItem.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropdownOptions;
