import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setUserDetails,
  getLoginUserData,
  setUserVer,
} from "../../actions/homeAction/homeAction";
import Controller from "../../components/controller/controller";

export class ControllerCont extends Component {
  render() {
    return <Controller {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    common: store.common,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    setUserDetails: (payload) => {
      dispatch(setUserDetails(payload));
    },
    getLoginUserData: (payload) => {
      dispatch(getLoginUserData(payload));
    },
    setUserVer: (payload) => {
      dispatch(setUserVer(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ControllerCont);
