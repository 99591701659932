import UNIVERSAL from "../../config/config";
import {
  SET_TIMELINES,
  SET_CERTIFICATES,
  SET_GROUP_INFO,
  SET_GROUP_LIST_FRONT,
  SET_LANGUAGES,
  SET_QUIZ_INFO,
  SET_FRONT_QUIZ_LIST,
  SET_FRONT_PROMOTION_LIST,
  SET_USER_DETAILS,
  SET_VER,
} from "../../constants/commonConst";
import { set_loader, unset_loader } from "../loader/loaderAction";
import md5 from "md5";

var base64 = require("base-64");

const authToken = localStorage.getItem("authToken");

export function setTimelines(payload) {
  return {
    type: SET_TIMELINES,
    payload: payload,
  };
}

export function setCertificates(payload) {
  return {
    type: SET_CERTIFICATES,
    payload: payload,
  };
}

export function setGroupListFront(payload) {
  return {
    type: SET_GROUP_LIST_FRONT,
    payload: payload,
  };
}

export function setGroupInfo(payload) {
  return {
    type: SET_GROUP_INFO,
    payload: payload,
  };
}

export function setFrontQuizList(payload) {
  return {
    type: SET_FRONT_QUIZ_LIST,
    payload: payload,
  };
}

export function setLanguages(payload) {
  return {
    type: SET_LANGUAGES,
    payload: payload,
  };
}

export function setFrontPromotionList(payload) {
  return {
    type: SET_FRONT_PROMOTION_LIST,
    payload: payload,
  };
}

export function setUserDetails(payload) {
  return {
    type: SET_USER_DETAILS,
    payload: payload,
  };
}

export function setUserVer(payload) {
  return {
    type: SET_VER,
    payload: payload,
  };
}

// GET APIs
export const getTimelines = ({ user_id, ver }) => {
  return (dispatch) => {
    dispatch(set_loader());
    let myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + base64.encode(user_id + ":" + ver)
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    // https://www.talabulilm.com/api2022/profile/user/profilePromotion
    return fetch(
      UNIVERSAL.BASEURL + "/api2022/profile/user/profilePromotion",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(unset_loader());
        dispatch(setTimelines(responseJson));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getCertificates = (user_id) => {
  return (dispatch) => {
    dispatch(set_loader());
    let requestOptions = {
      method: "GET",
    };
    return fetch(
      `https://www.talabulilm.com/istifaadah/api/api/get/certificate/${user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(unset_loader());
        dispatch(setCertificates(responseJson?.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getGroupListFront = ({ user_id, ver }) => {
  return (dispatch) => {
    dispatch(set_loader());
    let myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + base64.encode(user_id + ":" + ver)
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    return fetch(
      `https://imtihaan.talabulilm.com/imtihaan/user/group_list_front`,
      requestOptions
    )
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(unset_loader());
        dispatch(setGroupListFront(responseJson.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getGroupInfo = ({ label, user_id, ver }) => {
  return (dispatch) => {
    // dispatch(set_loader())
    let myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + base64.encode(user_id + ":" + ver)
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    return fetch(
      `https://imtihaan.talabulilm.com/imtihaan/user/general_info/${label}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((responseJson) => {
        // dispatch(unset_loader())
        dispatch(setGroupInfo(responseJson.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getFrontQuizList = (user_id, list_id) => {
  return (dispatch) => {
    dispatch(set_loader());
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic " + authToken);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    return fetch(
      `https://imtihaan.talabulilm.com/imtihaan/user/front_quiz_list/${list_id}/all`,
      requestOptions
    )
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(unset_loader());
        dispatch(setFrontQuizList(responseJson.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getLanguages = () => {
  return (dispatch) => {
    dispatch(set_loader());
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic " + authToken);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    return fetch(
      `https://imtihaan.talabulilm.com/imtihaan/user/langauge_list`,
      requestOptions
    )
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(unset_loader());
        dispatch(setLanguages(responseJson.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getFrontPromotionList = ({ user_id, ver }) => {
  return (dispatch) => {
    dispatch(set_loader());
    let myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + base64.encode(user_id + ":" + ver)
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    return fetch(
      `https://imtihaan.talabulilm.com/imtihaan/user/group_list_front_promoted`,
      requestOptions
    )
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(unset_loader());
        dispatch(setFrontPromotionList(responseJson.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getLoginUserData = (its_id) => {
  return (dispatch) => {
    dispatch(set_loader());
    const idn = "ohbat";
    const key = md5("b2dc6f66288e2fd4dacc9d180970af08" + its_id);
    const url =
      "https://talabulilm.com/imtihaan/imtihaan-api/api/get_its_details/" +
      its_id +
      "/" +
      key +
      "/" +
      idn;
    fetch(url, {
      method: "get",
    })
      .then((response) => response.json())
      .then((result) => {
        dispatch(unset_loader());
        localStorage.setItem("userData2", JSON.stringify(result.data));
        dispatch(setUserDetails(result.data));
      });
  };
};
