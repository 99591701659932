import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogContent,
  DialogActions,
  Slide,
  IconButton,
} from "@material-ui/core";
import CustomButton from "../UI/button";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LinearProgress from "@material-ui/core/LinearProgress";
import html2canvas from "html2canvas";
import ShareIcon from "@material-ui/icons/Share";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  inActiveAccordion: {
    backgroundColor: "#457B9D",
    color: "#F1FAEE",
    textTransform: "uppercase",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  inActiveAccordionDetails: {
    backgroundColor: "#F1FAEE",
    color: "#457B9D",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  userName: {
    position: "absolute",
    marginTop: "71px",
    marginLeft: "40px",
    color: "white",
    fontSize: "14px",
    width: "200px",
    textAlign: "center",
    fontWeight: 600,
  },
  inActiveAccordionButton: {
    borderRadius: 5,
    border: "1px solid #709395",
    textTransform: "none",
    fontWeight: "bold",
    textDecoration: "none",
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "15px",
      justifyContent: "right",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ paddingRight: 8, paddingLeft: 8 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

export default function Quiz(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [totalAttemptedCount, setTotalAttemptedCount] = useState();
  const [totalCount, setTotalCount] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);

  const [image, setImage] = useState(null);
  const [shareClicked, setShareClicked] = useState(false);

  //image load dynamic
  useEffect(() => {
    const fetchImage = async () => {
      console.log("totalAttemptedCount", totalAttemptedCount);
      try {
        const response = await import(
          `../../images/Imtihaan_1445H_badge_${totalAttemptedCount}.png`
        ); // change relative path to suit your needs
        setImage(response.default);
      } catch (err) {
        console.log("err: ", err);
      }
    };
    totalAttemptedCount && fetchImage();
  }, [totalAttemptedCount]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const { match, common, getFrontQuizList, getLanguages } = props;

  useEffect(() => {
    getFrontQuizList(common.userDetails.its_id, match.params.id);
    getLanguages();
  }, [common.userDetails]);

  useEffect(() => {
    if (common) {
      const data = common?.frontQuizList[common?.languages[value]?.code];
      setTotalAttemptedCount(
        data?.filter((item) => item.attended === "1").length
      );
      console.log(data?.attended);
      setTotalCount(data?.length);
    }
  }, [common, value]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleShare = () => {
    setShareClicked(true);
    const shareContent = document.getElementById("shareContent");

    if (!shareContent) {
      console.error("Element with ID 'shareContent' not found.");
      return;
    }

    html2canvas(shareContent)
      .then((canvas) => {
        const imgData = canvas.toBlob((blob) => {
          const files = [
            new File([blob], "talabulilm_imtihaan.png", { type: "image/png" }),
          ];

          navigator
            .share({
              text: "Check out this image and text: " + window.location.href,
              files,
            })
            .catch((error) => {
              console.error("Error sharing via Web Share API:", error);
            });
        }, "image/png");
      })
      .catch((error) => {
        console.error("Error capturing canvas:", error);
      });
  };

  const handleDownload = () => {
    html2canvas(document.getElementById("shareContent")).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Create a temporary link and trigger a click to simulate download
      const tempLink = document.createElement("a");
      tempLink.href = imgData;
      tempLink.download = "talabulilm_imtihaan.png";
      tempLink.click();
    });
  };

  return (
    <div>
      <AppBar position="static" style={{ background: "#457B9D" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          {common.languages &&
            common.languages.map((lang, i) => (
              <Tab label={lang.title} {...a11yProps(i)} />
            ))}
        </Tabs>
      </AppBar>

      {/* <Card>
        <CardContent>
          <Grid container style={{ alignItems: "center" }}>
            <Grid item xs={12}>
              <div style={{ paddingTop: "10px" }}>
                {`You have completed ${totalAttemptedCount} out of ${totalCount} papers.`}
              </div>
            </Grid>
            <Grid item xs={12} md={10} sm={12}>
              <BorderLinearProgress
                variant="determinate"
                value={(totalAttemptedCount * 100) / totalCount}
              />
            </Grid>
            <Grid item xs={12} md={2} sm={12} className={classes.gridItem}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpen()}
              >
                Share
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card> */}

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {common.languages &&
          common.languages.map((lang, i) => {
            return (
              <TabPanel value={value} index={i} dir={theme.direction}>
                <Grid container>
                  {common.frontQuizList &&
                    common.frontQuizList[lang.code] &&
                    common.frontQuizList[lang.code].map((quiz) => (
                      <>
                        <Grid item xs={12} md={4}>
                          <div style={{ padding: 5 }}>
                            <Accordion expanded={true}>
                              <AccordionSummary
                                className={classes.inActiveAccordion}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography className={classes.heading}>
                                  {quiz.name}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails
                                className={classes.inActiveAccordionDetails}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: quiz.description,
                                  }}
                                />
                              </AccordionDetails>
                              <AccordionActions
                                className={classes.inActiveAccordionDetails}
                              >
                                {quiz.attended > 0 ? (
                                  <CustomButton
                                    disabled
                                    className={classes.inActiveAccordionButton}
                                    variant="contained"
                                    color="secondary"
                                  >
                                    Already Attempted
                                  </CustomButton>
                                ) : new Date(quiz.start_date) > new Date() ? (
                                  <CustomButton
                                    disabled
                                    className={classes.inActiveAccordionButton}
                                    variant="contained"
                                    color="secondary"
                                  >
                                    Coming Soon
                                  </CustomButton>
                                ) : (
                                  <CustomButton
                                    endIcon={<ChevronRightIcon />}
                                    href={
                                      `/quiz/${quiz.folder_name}/?` + Date.now()
                                    }
                                    className={classes.inActiveAccordionButton}
                                    variant="contained"
                                    color="secondary"
                                  >
                                    Attempt
                                  </CustomButton>
                                )}
                              </AccordionActions>
                            </Accordion>
                          </div>
                        </Grid>
                      </>
                    ))}
                </Grid>
              </TabPanel>
            );
          })}
      </SwipeableViews>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent id="shareContent">
          <p className={classes.userName}>{common?.userDetails?.name}</p>
          <img src={image} alt="img" width={280} />
        </DialogContent>
        <DialogActions>
          <IconButton edge="end" color="inherit" onClick={handleShare}>
            <ShareIcon />
          </IconButton>
          <IconButton edge="end" color="inherit" onClick={handleDownload}>
            <CloudDownloadIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
