import React, { useState, useEffect } from "react";
import {
  AppBar,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";

const GroupEditForm = ({ handleCloseEdit, editData, selectRowId }) => {
  const [formData, setFormData] = useState({
    name: editData ? editData.name : "",
    time_limit: editData ? editData.time_limit : "",
    is_active: editData ? editData.is_active : "",
    start_date: editData ? editData.start_date : "",
    end_date: editData ? editData.end_date : "",
    user_its: editData ? editData.created_by : "",
    description: editData ? editData.description : "",
    id: selectRowId,
  });
  const authToken = localStorage.getItem("authToken");
  // New state to hold the edited data before submitting
  const [editedData, setEditedData] = useState([]);

  const [editorData, setEditorData] = useState(
    editData ? editData.description : ""
  );

  const handleChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `https://imtihaan.talabulilm.com/imtihaan/admin/group_data`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${authToken}`,
          },
          body: JSON.stringify({
            ...formData,
            description: editorData,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit form data");
      }

      const responseData = await response.json();
      // console.log("Group Data Edited successfully:", responseData);
      toast.success("Group Data Edited successfully");

      setEditorData("");
    } catch (error) {
      // console.error("Error in Data Editing", error.message);
      toast.error("Error in Data Editing");
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;

    setFormData({
      ...formData,
      [name]: inputValue,
    });

    // setEditedData({
    //   ...editedData,
    //   [name]: inputValue,
    //   group_id: formData.group_id,
    // });
  };

  return (
    <>
      <AppBar sx={{ position: "static", backgroundColor: "#1D3557" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Edit Group Info
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseEdit}
            aria-label="close"
          >
            <FaTimes />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Form onSubmit={handleSubmit} name="GroupEditForm" id="GroupEditForm">
          <Row>
            {/* Left Form */}
            <Col xs={12} md={5}>
              <Form.Group className="mb-2">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <Form.Group controlId="created_by">
                    <Form.Control
                      name="created_by"
                      type="hidden"
                      placeholder="Enter Your Its_ID"
                      value={formData.created_by === formData.user_its}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="name">
                    <Form.Control
                      name="name"
                      type="text"
                      placeholder="Enter Name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="time_limit">
                    <Form.Control
                      name="time_limit"
                      type="number"
                      placeholder="Enter Time limit"
                      value={formData.time_limit}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="is_active">
                    <Form.Check
                      name="is_active"
                      type="checkbox"
                      label="Is Active"
                      checked={formData.is_active}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </div>
              </Form.Group>
              <Form.Text>
                <span style={{ color: "red" }}>Note</span>: End Date must be
                Higher Than the Start Date
              </Form.Text>

              <Row>
                <Col>
                  <Form.Group controlId="startDate">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="start_date"
                      value={formData.start_date}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Button style={{ marginTop: "20px" }} type="submit">
                    Submit
                  </Button>
                </Col>
                <Col>
                  <Form.Group controlId="endDate">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="end_date"
                      value={formData.end_date}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            {/* Right Form */}
            <Col xs={12} md={7}>
              <Form.Label>Description</Form.Label>
              <CKEditor
                name="description"
                editor={ClassicEditor}
                data={editorData}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Form>
      </DialogContent>
    </>
  );
};

export default GroupEditForm;
