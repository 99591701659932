import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getCertificates,
  getTimelines,
  getGroupListFront,
  getGroupInfo,
  getFrontPromotionList,
  setUserDetails,
  getLoginUserData,
  setUserVer
} from '../../actions/homeAction/homeAction'
import Home from "../../components/home/home";

export class HomeCont extends Component {
  render() {
    return <Home {...this.props} />;
  }
}

export const mapStateToProps = (store) => {
  return {
    common: store.common,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    getCertificates: (user_id) => {
      dispatch(getCertificates(user_id))
    },
    getGroupListFront: (user_id) => {
      dispatch(getGroupListFront(user_id))
    },
    getGroupInfo: (label) => {
      dispatch(getGroupInfo(label))
    },
    getTimelines: (its_id, ver) => {
      dispatch(getTimelines(its_id, ver))
    },
    getFrontPromotionList: (user_id) => {
      dispatch(getFrontPromotionList(user_id))
    },
    setUserDetails: (payload) => {
      dispatch(setUserDetails(payload))
    },
    getLoginUserData: (payload) => {
      dispatch(getLoginUserData(payload))
    },
    setUserVer: (payload) => {
      dispatch(setUserVer(payload))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeCont);
