import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaDownload, FaEye } from "react-icons/fa";
import { ThreeDots } from "react-loader-spinner";
import JamaatWiseReport from "./JamaatWiseReport";
import AdminMenu from "../AdminMenu";
const authToken = localStorage.getItem("authToken");
const columns = [
  { id: "jamaat", label: "Jamaat" },
  { id: "totalFemale", label: "Total Female" },
  { id: "totalMale", label: "Total Male" },
  { id: "totalUser", label: "Total User" },
  { id: "action", label: "Action" },
];

export default function JammatReport() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://imtihaan.talabulilm.com/imtihaan/admin/jamat_wise_user_count",
          {
            headers: {
              Authorization: "Basic " + authToken,
            },
          }
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // --------------CSV-----------------------------
  const downloadCsv = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      data.map((row) => Object.values(row).join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "jamaat-report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  // --------------Filter-----------------------------
  const [filteredData, setFilteredData] = useState([]);

  const handleFilterChange = (event, columnId) => {
    const inputValue = event.target.value.toLowerCase();
    const filteredRows = data.filter((row) =>
      row[columnId].toLowerCase().includes(inputValue)
    );
    setFilteredData(filteredRows);
  };
  // --------------Filter Table as per the Jammat Name-----------------------------
  const [jamaatWiseData, setJamaatWiseData] = useState([]);
  const [jamaat, setJamaat] = useState("");
  const getTheJamaatName = async (jamaat) => {
    try {
      const response = await axios.get(
        `https://talabulilm.com/imtihaan/imtihaan-api/api/get/jamat_wise_user_list/${jamaat}`
      );
      setJamaatWiseData(response.data.data);
      setJamaat(jamaat);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="container-admin">
      <AdminMenu />
      {jamaatWiseData.length === 0 ? (
        <Paper
          sx={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            margin: "20px",
          }}
        >
          <div style={{ opacity: "0.8", margin: "20px", fontWeight: "bold" }}>
            <p>TOTAL USERS JAMAT WISE</p>
            <hr className="underline" />
          </div>

          {data.length === 0 ? (
            <span
              style={{
                display: "block",
                width: "50px",
                margin: "auto",
                position: "inline",
                textAlign: "center",
              }}
            >
              <ThreeDots color="darkblue" size={50} />
            </span>
          ) : (
            <>
              <span
                onClick={downloadCsv}
                style={{
                  fontSize: "0.9em",
                  cursor: "pointer",
                  marginLeft: "20px",
                  marginTop: "10px",
                  color: "green",
                }}
              >
                <FaDownload size={15} color="green" /> Download Csv
              </span>
              <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align="left"
                          style={{
                            minWidth: 150,
                            fontSize: "19px",
                            color: "blue",
                          }}
                        >
                          {column.label}
                          <TextField
                            id={column.id + "-filter"}
                            label={`Filter ${column.label}`}
                            variant="outlined"
                            size="small"
                            onChange={(event) =>
                              handleFilterChange(event, column.id)
                            }
                            style={{ marginLeft: "10px" }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(filteredData.length > 0 ? filteredData : data)
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const totalUser =
                          parseInt(row.totalFemale) + parseInt(row.totalMale);
                        const action = (
                          <FaEye
                            size={18}
                            color="blue"
                            alt="eyelogo"
                            className="icon"
                            onClick={() => getTheJamaatName(row.jamaat)}
                          />
                        );
                        return (
                          <TableRow hover key={index}>
                            <TableCell align="left">{row.jamaat}</TableCell>
                            <TableCell align="left">
                              {row.totalFemale}
                            </TableCell>
                            <TableCell align="left">{row.totalMale}</TableCell>
                            <TableCell align="left">{totalUser}</TableCell>
                            <TableCell align="left">{action}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="span"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) => {
                  return (
                    <span
                      style={{
                        color: "blue",
                        fontWeight: "bold",
                        display: "flex",
                      }}
                    >
                      {`${from}-${to} of ${count}`}
                    </span>
                  );
                }}
                sx={{
                  margin: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  "& .MuiButtonBase-root": {
                    borderRadius: "20px",
                    padding: "10px",
                    marginRight: "10px",
                  },
                  "& .MuiSelect-selectMenu": {
                    color: "red",
                    paddingRight: "10px",
                  },
                  "& .MuiTablePagination-actions button": {
                    backgroundColor: "#545cd8",
                    gap: "10px",
                    color: "white",
                    borderRadius: "5px",
                    padding: "8px",
                  },
                }}
              />
            </>
          )}
        </Paper>
      ) : (
        <JamaatWiseReport jamaatWiseData={jamaatWiseData} jamaat={jamaat} />
      )}
    </div>
  );
}
